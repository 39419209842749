<!-- //* license expiry component -->
<template>
    <div v-if="core && core.license_expires <= 30 && $hasUserRole('Power User')" style="margin: 0.8rem">
        <div class="d-flex flex-wrap">
            <div>
                <b-badge v-on:click="hideTimer()" v-show="!show_expiry_timer" ariant="warning" class="text-dark m-1 p-3 bg-warning">License expires in {{ core.license_expires }} days</b-badge>
            </div>
        </div>
    </div>
</template>

<script>
import { adminComputed } from "@/state/helpers";
import { uxToggleComputed, uxToggleMethods } from "@/state/helpers";

export default {
    name: 'LicenseExpireTimer',
    computed:{
        ...adminComputed,
        ...uxToggleComputed,
    },
    methods: {
        ...uxToggleMethods,
        hideTimer(){
            this.setExpiryTimerState({
                state: true,
            })
        },
    }
}
</script>