//* create a new date without hrs/min/sec
//eslint-disable-next-line
import { format, parseISO } from 'date-fns'
import { isBefore, isAfter, isEqual } from 'date-fns';
export default {
    methods:{
        parseDateString(date_string) {
            if(!date_string){
                return '';
            }
            // Split the date string by '-' to get the individual components
            const parts = date_string.split('-');
        
            // Map the month names to their corresponding month numbers
            const months = {
                Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
                Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
            };
        
            // Extract the day, month, and year from the parts
            const day = parseInt(parts[0], 10);
            const month = months[parts[1]];
            const year = parseInt(parts[2], 10);
        
            // Create and return the Date object
            return new Date(year, month, day);
        },
        getDate(date){
            //* 
            if(typeof date === 'string'){
                date = new Date(date);
                date = format(date,'dd-MMM-yyyy');
                date = new Date(date);
            }
            else{
                date = format(date,'dd-MMM-yyyy');
                date = new Date(date);
            }
            return date;
        },
        getSimpleDate(date, delimiter = '-'){
            return format(this.getDate(date), `dd${delimiter}MMM${delimiter}yyyy`)
        },
        getBackendDateFormat(date){
            return format(this.getDate(date), 'yyyy-MM-dd');
        },
        startDateCheck(start, end){
            return isBefore(this.getDate(start), this.getDate(end)) || isEqual(this.getDate(start), this.getDate(end))
        },
        endDateCheck(end, start){
            return isAfter(this.getDate(end), this.getDate(start)) || isEqual(this.getDate(start), this.getDate(end))
        }
    }
}