<template>
    <b-modal :title='getTitle' id='milestoneModal' hide-footer>
        <b-form>
            <b-row>
                <b-col cols="4">
                    <label class="mb-0" for="">Milestone Year</label>
                    <date-picker
                    v-model="milestone.year"
                    type="year"
                    valueType="format"
                    format="YYYY"
                    lang="en"
                    placeholder="Select Year"
                    :disabled-date="handleDisabledDate"
                    ></date-picker>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col>
                    <label class="mb-0" for="">Name (optional)</label>
                    <b-form-input size="sm" v-model="milestone.name"></b-form-input>
                </b-col>
            </b-row>
        </b-form>
        
        
        <!-- footer -->
        <div class='d-flex'>
            <b-button v-show="milestone.id != -1" @click="deleteMilestoneItem" class="mt-2" variant="danger">Delete</b-button>
            <b-button @click='save' class='ms-auto mt-2' variant='success'>Save</b-button>
        </div>
        <b-overlay no-wrap show v-if="is_loading">

        </b-overlay>
    </b-modal>

</template>

<script>
import { activityComputed, adminComputed, activityMethods, levelComputed } from '@/state/helpers';
import { format, parse } from 'date-fns';
import { startOfYear, endOfYear } from 'date-fns';
import DatePicker from 'vue2-datepicker'
import { cloneDeep } from 'lodash';
export default {
    components:{ DatePicker},
    data:() => ({
        is_loading: false,
        owner_query: '',
        users:[],
        datepicker_year: null,
        milestone:{
			"owner_id": "1",
			"level_id": -1,
			"start_date": "2021-11-12",
			"id": "-1",
			"draft": "1",
			"month": "",
			"parent_id": 0,
			"year": '2022',
			"quarter": "4",
			"name": "",
			"end_date": "2022-11-12"
		},
    
    }),
    props:{
        milestone_item:{}
    },
    computed:{
        ...adminComputed,
        ...activityComputed,
        ...levelComputed,
        getTitle(){
            return this.milestone.id != -1
            ? `Edit ${this.milestone.name}`
            : 'New Milestone'
        },
        getLinkedLevel(){
            return this.selected_level ? this.selected_level.name : '';
        },
        used_milestone_years(){
            //return unavailable years
            return this.company_milestones.map( item => {
                return item.year;
            })
        },
        default_milestone(){
            return {
                "owner_id": -1,
                "level_id": -1,
                "start_date": null,
                "id": -1,
                "draft": 0,
                "month": -1,
                "parent_id": 0,
                "year": null,
                "quarter": -1,
                "name": "",
                "end_date": "null"
            };
        }
    },
    methods:{
        ...activityMethods,
        findClosestAvailableYear(disabledYears) {
            const today = new Date();
            const currentYear = today.getFullYear();

            let closestAvailableYear = currentYear;

            // Find the closest available year after the current year
            for (let i = currentYear + 1; i <= 2100; i++) {
                if (!disabledYears.includes(i)) {
                closestAvailableYear = i;
                break;
                }
            }

            // If no available years after current year, find closest available year before current year
            if (closestAvailableYear === currentYear) {
                for (let i = currentYear - 1; i >= 1900; i--) {
                if (!disabledYears.includes(i)) {
                    closestAvailableYear = i;
                    break;
                }
                }
            }

            return closestAvailableYear;
        },
        deleteMilestoneItem(){
            this.$swal.fire({
                title: "Delete selected milestones?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    this.is_loading = true;
                    //* If user selects yes
                    this.deleteMilestone({id: this.milestone.id})
                    .then(()=>{
                        this.reloadMilestone()
                        .then(()=>{
                            this.$bvModal.hide('milestoneModal')
                        })
                    })
                    
                }
            });
        },
        save(){
            if(this.milestone.year == null){
                this.$swal.fire('Please select a year');
                return;
            }

            let payload = {
                id: this.milestone.id,
                parent_id: -1,
                name: this.milestone.name,
                year: Number(this.milestone.year),
                draft: 0,
                month: '-1',
                quarter: '-1',
                start_date: format(startOfYear(new Date(Number(this.milestone.year), 0, 1)), 'yyyy-MM-dd'),
                end_date: format(endOfYear(new Date(Number(this.milestone.year), 11, 31)), 'yyy-MM-dd'), 
                notes: '',
                owner_id: this.core.id,
                level_id: this.company_id,
            }

            this.is_loading = true;

            let params = {
                level_id: this.company_id,
                milestones: [payload]
            }

            console.log('SAVE PARAMS', params);
            this.saveMilestone(params)
            .then(()=>{
                this.reloadMilestone()
                .then(()=>{
                    this.$bvModal.hide('milestoneModal')
                })
            })
            .catch(()=>{
                this.is_loading = false;
            })
        },
        reloadMilestone() {
            return new Promise(resolve => {
                this.loadCompanyMilestones(this.company_id)
                .then(()=>{
                    resolve();
                })
            })
        },
        selectedOwner(item) {
            let email = item.split('|')[1];
            let email_string = email.trim();
            let user = this.all_users.find( item => {
                return item.email == email_string
            });
            this.milestone.owner_id = user.id
            //this.form.responsible_user_id = user.id
        },
        handleDisabledDate(picker_date){
            let date = format(picker_date, 'yyyy');
            return this.used_milestone_years.includes(date);
        },
        populateList(){
            let users = [];
            this.all_users.forEach( user =>{
                users.push(`${user.name} | ${user.email}`)
            });
            this.users = users;
        },
        convertYearToDate(year) {
            // Create a date object for the last day of the year
            const lastDayOfYear = parse(`${year}-12-31`, 'yyyy-MM-dd', new Date());

            // Set the time to 10 PM in UTC
            lastDayOfYear.setUTCHours(22, 0, 0, 0);

            // Format the date
            this.formattedDate = format(lastDayOfYear, 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'');
            this.datepicker_year = this.formattedDate;
            },
        selectedResponsibleUser(item) {
            let email = item.split('|')[1];
            let email_string = email.trim();
            let user = this.all_users.find( item => {
                return item.email == email_string
            });
            this.milestone.owner_id = user.id
        },
        initForm(){
            if(this.milestone_item){
                this.milestone = cloneDeep(this.company_milestones.find( item => {
                    return item.id == this.milestone_item.id;
                }))
                this.milestone.owner_id = this.milestone.owner_user_id;
                let owner = this.all_users.find( item => {
                    return item.id == this.milestone.owner_user_id
                });
                this.owner_query = `${owner.name} | ${owner.email}`

                this.convertYearToDate(this.milestone.year);
            }
            else{
                this.milestone.year = null;
            }
            
        }
},
    mounted(){
        this.populateList();
        this.initForm();
    },
}
</script>

<style>
#milestoneModal___BV_modal_body_ > form > div > div > div > div > i{
    visibility: hidden;
}
#milestoneModal___BV_modal_body_ > form > div > div > div > div > input{
    width: 100%;
    height: 1.7rem !important;
}
.typeahead-input-owner{
    height: calc(1.5em + 0.5rem + 2px);
}
</style>