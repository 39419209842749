<template>
    <div> 
    <b-modal hide-footer title="Changelog" v-if="show_modal" id="changelogModal">
        <div v-if="changelog_data != null" class="mt-0">
            <p v-html="current_changelog"></p>
        </div>
        <b-overlay no-wrap :show="is_loading" ></b-overlay>
    </b-modal>
</div>
</template>

<script>
import { version } from '../app.config.json';
import {
    levelComputed,
    peopleComputed,
    adminComputed,
    adminMethods,
} from "@/state/helpers";
export default {
    data: () => ({
        show_modal: true,
        is_loading: true,
    }),
    methods:{
        ...adminMethods,    
    },
    props: {
    },
    computed: {
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        current_changelog(){
            let item = this.changelog_data.find( element => {
                return element.version_no == version;
            })
            if(item){
                return item.changelog_body
            }
            return 'Empty';
        }
    },
    watch: {
    },
    mounted() {
        this.show_modal = true;
        this.loadChangelog()
        .then(()=>{
            this.is_loading = false;
        })
    },
    beforeDestroy() {
    },
};
</script>

<style>
</style>
