<template>
    <div>
        <span v-if="edit">
            <span class="inputField d-flex">
                <vue-typeahead-bootstrap
                    ref="testFocusInput"
                    :data="jobs"
                    v-model="query"
                    @hit="selectJob($event)"
                    placeholder="Search for a job title"
                    :minMatchingChars="Number(-1)"
                    :autoClose="true"
                    prepend="Job Title:"
                ></vue-typeahead-bootstrap>
                <span class="ms-1">
                    <b-button
                        v-if="
                            false &&
                                valid_job_flag == false &&
                                this.query !== ''
                        "
                        @click="createNewCompanyJob"
                        variant="primary"
                        class="me-1"
                        title="Create a new job"
                    >
                        <i class="fas fa-plus me-2  "></i>Create New Job
                    </b-button>
                    <b-button
                        variant="danger"
                        @click="closeBtnAction"
                        v-if="isNewUser == false"
                    >
                        <i class="fas fa-times me-2"></i>Cancel
                    </b-button>
                </span>
            </span>
        </span>
        <span v-else class="mb-0 d-flex">
            <label v-show="!hideTitle" class="me-2 mb-0">Job: </label>
            {{ title }}
            <font-awesome-icon class="fa-fw ms-1 mt-1 editIcon" icon="fa-pen"
            v-if="isUserManager && hideEdit == false"
            @click="editAction"
            />
        </span>

        <b-modal
            title="Create New Job"
            size="lg"
            hide-footer
            v-if="show_modal"
            id="jobDetails"
        >
            <JobDetailsForm
                :is_new_job="true"
                :query="query"
                @changedJob="handleChangeJob"
            />
        </b-modal>
        <b-overlay :show="isLoading" no-wrap></b-overlay>
    </div>
</template>

<script>
import { format } from "date-fns";
import {
    levelComputed,
    levelMethods,
    peopleComputed,
    peopleMethods,
    adminComputed,
    adminMethods,
} from "@/state/helpers";
import JobDetailsForm from "./jobDetailsForm.vue";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import _ from "lodash";
import Swal from "sweetalert2";
export default {
    data: () => ({
        query: "",
        last_selected_id: -1,
        jobs: [],
        edit: false,
        valid_job_flag: true,
        show_modal: false,
    }),
    props: {
        autofocus: {
            default: true,
        },
        title: {
            default: "",
        },
        enableEdit: {
            default: false,
        },
        hideEdit: {
            //* hide the pen icon
            default: false,
        },
        isNewUser: {
            default: false,
        },
        hasValidJob: {
            default: false,
        },
        isLoading: {
            default: false,
        },
        changes_made_flag: {
            default: false,
        },
        hideTitle: {
            default: false
        }
    },
    computed: {
        ...levelComputed,
        ...peopleComputed,
        ...adminComputed,
        isUserManager() {
            let is_editing_as_manager = this.$isManagerCheck(
                this.core.id,
                this.selected_user_id
            );
            if (is_editing_as_manager) {
                return true;
            }
            return false;
        },
        getCompanyLevelId() {
            const tree = window.webix.$$("levelsTree");
            let item = tree.getItem(this.selected_level.id);
            let top_level_id = -1;
            if (item.parent_id === null) {
                //* current level is company level
                top_level_id = item.id;
            } else {
                top_level_id = tree.getParentId(this.selected_level.id);
                let found = false;
                while (!found) {
                    let new_parnet_id = tree.getParentId(top_level_id);
                    if (new_parnet_id === 0) {
                        found = true;
                    } else {
                        top_level_id = new_parnet_id;
                    }
                }
            }
            return top_level_id;
        },
    },
    watch: {
        edit() {
            if (this.edit == true) {
                this.query = "";
                if (this.hasValidJob) {
                    //* only set query text if there is a valid job name
                    this.query = _.cloneDeep(this.title);
                }
                //if (this.autofocus) {
                //    this.$nextTick(() => {
                //        //* get input field from component and then set its focus
                //        let input = this.$refs["testFocusInput"].$refs["input"];
                //        input.focus();
                //    });
                //}
            }
        },
        query: _.debounce(function(newValue) {
            this.validJobCheck(newValue);
        }, 300),
    },
    components: { VueTypeaheadBootstrap, JobDetailsForm },
    methods: {
        ...levelMethods,
        ...peopleMethods,
        ...adminMethods,
        editAction() {
            if (this.changes_made_flag == false) {
                this.edit = true;
            } else {
                Swal.fire(
                    "Please save changes to the employee details form first"
                );
            }
        },
        createNewCompanyJob() {
            let params = {
                id: -1,
                title: this.query,
                level_id: this.getCompanyLevelId,
            };
            console.log("New Level params", params);
            this.showNavigationPrompt(params);
        },
        showNavigationPrompt(params) {
            //* ask a user if they would like to navigate to the newly created job in jobs tab
            Swal.fire({
                title: "Navigate to the newly created job after saving?",
                text: "Open new job in the Job Configuration tab",
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.saveHiringJob([params]) //* endpoint expects array
                        .then(() => {
                            let new_id = 40;
                            //* get new job id
                            this.$eventHub.$emit("openNewlyCreatedJob", new_id);
                        });
                }
            });
        },
        showModal() {
            this.show_modal = true;
            this.$nextTick(() => {
                this.$bvModal.show("jobDetails");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (
                        event.type == "hidden" &&
                        event.componentId == "jobDetails"
                    ) {
                        if (this.isNewUser == false) {
                            this.edit = false;
                            this.show_modal = false;
                            this.query = "";
                            this.edit = false;
                        }
                    }
                });
            });
        },
        setJobsData() {
            this.jobs = [];
            /*Object.keys(this.menu_users).forEach((key) => {
                this.jobs.push(key);
            });
            
            this.levelHiringData.forEach( job =>{
                if(this.jobs.includes(job.job_title) == false){
                    this.jobs.push(job.job_title);
                }
            })*/
            this.companyJobs.forEach((job) => {
                this.jobs.push(job.title);
            });
        },
        validJobCheck(query) {
            if (query === "") {
                //* dont show add button if string is empty
                this.valid_job_flag = true;
            }
            //* --
            else if (this.jobs.includes(query)) {
                this.valid_job_flag = true;
            } else {
                //* Job doesn't exist, so add button to create a new job
                this.valid_job_flag = false;
            }

            //* updates the text variable in the addEmployeeWizard
            if (this.isNewUser) {
                //last_selected_id
                let job = {
                    //return -1 if the entered text doesnt match any job
                    id: this.valid_job_flag ? this.last_selected_id : -1,
                    text: this.query,
                };
                this.$emit("returnJob", job);
            }
        },
        lookupUser: _.debounce(function() {}, 500),
        selectJob(data) {
            let index = _.findIndex(this.companyJobs, { title: data });
            if (index != -1) {
                this.last_selected_id = this.companyJobs[index].id;
                if (this.isNewUser) {
                    let job = {
                        id: this.companyJobs[index].id,
                        text: data,
                    };
                    this.$nextTick(() => {
                        this.$emit("returnJob", job);
                    });
                } else {
                    let date = new Date();
                    date = format(date, "yyyy-MM-dd");
                    let update_job_params = {
                        user_id: Number(this.selected_user_id),
                        job_id: this.companyJobs[index].id,
                        start_date: date,
                    };
                    this.updateEmployeeJob(update_job_params).then(() => {
                        this.$emit("changedJob");
                        this.edit = false;
                    });
                }
            } else {
                console.log("Error finding job ID");
            }
        },
        /*selectJob(data){
            let index = _.findIndex(this.levelHiringData, {job_title: data})
            if(index != -1){
                this.last_selected_id = this.levelHiringData[index].id;
                if(this.isNewUser){
                    let job = {
                        id: this.levelHiringData[index].id,
                        text: data,
                    }
                    this.$nextTick(()=>{
                        this.$emit('returnJob', job);
                    })
                }
                else{
                    let date = new Date();
                    date = format(date,'yyyy-MM-dd');
                    let update_job_params = {
                        user_id: this.selected_user_id,
                        job_id: this.levelHiringData[index].id,
                        start_date:date,
                    }
                    this.updateEmployeeJob(update_job_params)
                    .then(()=>{
                        this.$emit('changedJob');
                        this.edit = false;
                    })
                }
            }
            else{
                console.log('Error finding job ID');
            }
        },*/
        handleChangeJob() {
            if (this.isNewUser == false) {
                this.$emit("changedJob");
            } else {
                this.selectJob(this.query);
            }
        },
        closeBtnAction() {
            if (this.isNewUser) {
                this.$emit("returnJob", "");
            } else {
                this.edit = false;
            }
        },
    },
    mounted() {
        this.edit = this.enableEdit;
        this.setJobsData();

        this.$eventHub.$on("showNewJobModal", this.showModal);
        this.$eventHub.$on("updateJobsTypeahead", this.setJobsData);
    },
    beforeDestroy() {
        this.$eventHub.$off("showNewJobModal");
        this.$eventHub.$off("updateJobsTypeahead");
    },
};
</script>

<style>
.iconButton {
    border: 1px solid lightgrey;
    border-radius: 0.2rem;
}
.iconButton:hover {
    border: 1px solid black;
}
.addJobIcon {
    color: rgb(49, 214, 57);
}
.addJobIcon:hover {
    cursor: pointer;
    color: black;
}
.editIcon {
    color: darkgray;
}

.editIcon:hover {
    cursor: pointer;
    color: black;
}

.inputField > div {
    width: 250px;
}
</style>
