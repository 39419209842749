<template>
    <div>
        <div class="media border-top border-light">
            <div class="media-body">
                <!-- <div class="d-flex">
                    <h6 class="mt-0 mb-1">
                        
                    </h6>
                </div> -->

                <div class="font-size-12 mb-0" >
                    <div>
                        <slot name="header"></slot>
                    </div>
                    
                    <h6>
                        <slot name="title"></slot>
                    </h6>
                </div>
                <!-- <div class="font-size-12 text-muted mb-1">
                    From: {{ fromName }}
                </div> -->

                <div class="font-size-12 text-muted">
                    <slot name="body"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({}),
    computed: {},
    methods: {},
    mounted() { },
}
</script>

<style></style>