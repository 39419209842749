<script>
import router from "@/router";
import { layoutComputed, layoutMethods, ganttComputed, ganttMethods } from "@/state/helpers";
import ChatSidenavContainer from "../../components/menu/chat/chatSidenavContainer.vue";
import NavBar from "@/components/nav-bar";
import SideBar from "@/components/side-bar";
import RightBar from "@/components/right-bar";
import Footer from "@/components/footer";

/**
 * Vertical layout
 */
export default {
  components: { NavBar, SideBar, RightBar, Footer, ChatSidenavContainer },
  data() {
    return {
      show_view: true,
      type: this.$store ? this.$store.state.layout.leftSidebarType : null || null,
      width: this.$store ? this.$store.state.layout.layoutWidth : null || null,
      isMenuCondensed: false,
    };
  },
  computed: {
    ...layoutComputed,
    ...ganttComputed
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");
    document.body.classList.remove("auth-body-bg");
  },
  methods: {
    ...layoutMethods,
    ...ganttMethods,
    startDebug(count = 50){
      this.$swal.fire({
                            icon: "info",
                            title: count,
                            toast: true,
                            position: "top",
                            showConfirmButton: false,
                            timer: 100,
                            timerProgressBar: true,
                        });
                        
      this.setMemLeakBool(false)//this.show_view = false;
      setTimeout(()=>{
        this.setMemLeakBool(true)
        setTimeout(()=>{
          if(count == 0){
            return;
          }
          this.startDebug(count-1);
        }, 400)
      }, 150)

    },
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
  },
  mounted() {
    if (this.loader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
  },
};
</script>

<template>
  <div>
    <div id="preloader">
      <div id="status">
        <div class="spinner-chase">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
        </div>
      </div>
    </div>
    <div id="layout-wrapper">
      <NavBar />
      <SideBar :is-condensed="isMenuCondensed" :type="leftSidebarType" :width="layoutWidth" />
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="main-content" id="mainContent">
        
        <div v-bind:class="((this.$route.name.includes('handbook')) || (this.fullscreen_kanban == true))?'page-content handbook kanban':'page-content'">
          <b-button v-if="false" @click="startDebug()">Debug Reload</b-button>
          <!-- Start Content-->
          <div v-if="show_view" class="container-fluid">
            <slot />
            <ChatSidenavContainer/>
          </div>
        </div>
        <Footer />
      </div>
      <RightBar />
    </div>
  </div>
</template>
