<!--
@Author: Calvin Green <calvin>
@Date:   2021-10-19T16:41:12+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-15T12:39:29+02:00
@Copyright: Diversitude 2021
-->
<script>
import appConfig from "@/app.config";
import changelogModal from "../components/changelogModal.vue";
export default {
  components: {
      changelogModal
  },
  data() {
    return {
      viewChangelog: false,
      show_modal: false
    }
  },
  
  computed:{
    getAppVersion(){
      return appConfig.version;
    }
  },
  methods: {
    showModal(){
        this.show_modal = true;
        this.$nextTick(()=>{
            this.show_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('changelogModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "changelogModal") {
                        this.show_modal = false;
                    }
                });
            })
        })
    },
  }
}
</script>


<template>
    <div>
      <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">

              <div class="text-sm-end d-none d-sm-block">
                <span>
                  Need help?
                  <span class="text-primary me-4">support@stratosfia.com</span>
                </span>
                <!--{{ new Date().getFullYear() }} © Powered by <a href="https://www.diversitude.com" target="_blank">Diversitude.</a></div>-->
                <span>{{ new Date().getFullYear() }} © Powered by <a href="https://www.diversitude.com" target="_blank">Stratosfia</a></span>
                <a @click="showModal" class="cursor pointer"><span class="ms-4">Version: {{getAppVersion}}</span></a> 
                <!-- <span class="ms-4">Version: {{getAppVersion}}</span> -->
              </div>
            </div>
          </div>
        </div>
      </footer>
      <changelogModal v-if="show_modal" />
     </div>
</template>
