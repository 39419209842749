<template>
    <!-- @todo Messages need to add a Date Row if its a new day -->
    <!-- Begin Message Component -->
    <b-overlay :show="is_chat_loading">
        <div :class="messageContainerClassName" @contextmenu.prevent="showContextMenu">
            <b-row align-h="center">
                <b-col :cols="1">&nbsp;</b-col>
                <b-col :cols="8">
                    <p :class="aboveMessageTextClassName" v-text="aboveMessageText"></p>
                </b-col>
            </b-row>
            
            <!-- End Message Component Top Row -->
            <b-row align-h="center" no-gutters>
                <b-col align-self="start" style="height:100%;" class="d-flex flex-column align-items-center text-center"
                    cols="1">
                    <strong v-text="getAboveProfileImageText"></strong>

                    <b-avatar :src="getChatUserProfileImage" class="align-baseline" :title="lastSeenText" size="2.5rem"
                        :badge="true" badge-variant="primary">
                        <template #badge>
                            <!-- Badge content goes here -->
                            <b-badge variant="primary" pill style="border-radius: 50% !important; font-weight: bold;"
                                :class="avatarOnlineIndicatorClassName">&nbsp;
                            </b-badge>
                        </template>
                    </b-avatar>
                </b-col>
                <b-col cols="8" style=""><!-- min-height:70px !important; -->
                    <div :class="messageClassName" style="min-height: 5rem;" class="">
                        <b-dropdown :disabled="isDeleted || isChatMessageRead" v-if="!isDeleted && !isChatMessageRead"
                            menu-class="dropdown-menu-end" no-caret class="no-focus chat-message-drop-dots" size="sm"
                            variant="outline">
                            <template #button-content>
                                <font-awesome-icon style="font-size: 1.2em; margin-top: 1px;"
                                    icon="fa-solid  fa-ellipsis" />
                            </template>
                            <b-dropdown-item v-if="false === true" @click="setMode('edit')" class="text-info">
                                <font-awesome-icon class="fa-fw me-1" icon="fa-solid  fa-pen" />
                                Edit Mode
                            </b-dropdown-item>
                            <b-dropdown-item @click="handleContextMenuAction('delete')" class="text-danger">
                                <font-awesome-icon class="fa-fw me-1" icon="fa-solid  fa-trash" />
                                Delete
                            </b-dropdown-item>
                        </b-dropdown>
                        <div class="w-100 message-content" v-html="messageHtmlContent" v-if="!isDeleted"></div>
                        <p class="text-muted" v-else>This message has been deleted</p>
                        <div class="ms-auto mt-auto">
                            <button class="emoji-invoker" size="sm" @click="toggleEmojiPicker" v-if="!isDeleted">
                                <svg style="font-size: 1.2em;" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                    class=" fill-current text-black">
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path
                                        d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row align-h="center" align-content="end">
                <b-col align-self="start" cols="1"> </b-col>
                <b-col cols="8">
                    <b-row>
                        <b-col cols="6">
                            <ul class="message-emoji-list" :id="`emoji-list-sync-${message.id}`" v-if="!isDeleted">
                                <transition-group name="fade" tag="div">
                                    <li :key="`emoji-item-${index}`" v-for="(item, emoji, index) in groupedReactions"
                                        :title="emoji">
                                        <b-badge :id="`message-${message.id}-emoji-badge-${index}`" variant="primary"
                                            style="border-radius: 0.2rem; font-weight: bold; font-size: 0.9rem"
                                            class="bg-primary bg-soft text-primary"
                                            @click="togglePopover(emoji, `message-${message.id}-emoji-badge-${index}`)">{{
        findEmoji(emoji) }}
                                            {{
        item.count }}</b-badge>
                                    </li>
                                </transition-group>
                            </ul>
                            <b-popover :target="`message-${message.id}-emoji-badge-0`" triggers="click blur"
                                :html="true" container=".message-emoji-list" placement="topright" :show="showPopover"
                                @hidden="popoverHidden" v-if="!isDeleted && hasReactions">
                                <!-- Popover content -->
                                <b-tabs v-model="selectedEmojiTab" content-class="mt-2">
                                    <b-tab v-for="(item, emoji) in groupedReactions"
                                        :key="`popover-tab-${emoji}-${message.id}`" :title="emoji">
                                        <template #title> {{ findEmoji(emoji) }} ({{ item.count }}) </template>
                                        <!-- Tab content -->
                                        <b-list-group>
                                            <b-list-group-item v-for="(reaction, index) in item.reactions" :key="index">
                                                {{ findUser(reaction.user_id).name }}</b-list-group-item>
                                        </b-list-group>
                                    </b-tab>
                                </b-tabs>
                            </b-popover>
                        </b-col>
                        <b-col cols="6" style="text-align: right; font-size: 1.5em;" v-if="!isDeleted">
                            <div v-if="!isDeleted" style="margin-right: 5px;">
                                <p v-if="isChatMessageRead" class="read" title="read"
                                    :id="`read-message-sync-${message.id}`">
                                    <font-awesome-icon :icon="['far', 'square-check']" />
                                </p>
                                <p v-else class="unread" title="unread">
                                    <font-awesome-icon :icon="['far', 'square']" />
                                </p>
                                <b-popover :target="`read-message-sync-${message.id}`" triggers="click blur"
                                    :html="true" placement="topright" :show="showReadPopover" @hidden="popoverHidden"
                                    v-if="!isDeleted && isChatMessageRead">
                                    <!-- Popover content -->
                                    <b-tabs v-model="selectedReadbyTab" content-class="mt-2">
                                        <b-tab :key="`popover-tab-read-${message.id}`" title="SEEN BY">
                                            <!-- Tab content -->
                                            <b-list-group>
                                                <b-list-group-item v-for="(user, index) in messageReadBy" :key="index">
                                                    {{ findUser(user.user_id).name }} - {{
        formatReadByDate(user.created_at)
                                                    }}</b-list-group-item>
                                            </b-list-group>
                                        </b-tab>
                                    </b-tabs>
                                </b-popover>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
                <VEmojiPicker style="z-index: 999 !important; position: absolute; right: 0; max-height:300px"
                    v-if="is_visible_emoji_picker && isCurrentMessageSelectedContext && !isDeleted"
                    @select="handleEmojiSelect" />
            </b-row>
            <!-- End Message Component -->
        </div>
    </b-overlay>
</template>

<script>
import { adminComputed, chatMethods, chatComputed, levelComputed } from '@/state/helpers';
import { formatISO9075, parseISO, formatRelative } from 'date-fns';
// eslint-disable-next-line
import { VEmojiPicker, emojisDefault, categoriesDefault } from 'v-emoji-picker';
import _ from 'lodash';

export default {
    name: 'chatMessage',
    props: {
        message: Object,
    },
    data() {
        return {
            showPopover: false,
            showReadPopover: false,
            selectedEmojiTab: null,
            popoverTarget: '',
            selectedReadbyTab: null,
            isContextMenuVisible: false,
            contextMenuX: 0,
            contextMenuY: 0,
            isEmojiPickerVisible: false,
        };
    },
    components: {
        VEmojiPicker,
    },
    computed: {
        ...adminComputed,
        ...chatComputed,
        ...levelComputed,
        is_chat_loading() {
            return false;
        },
        aboveMessageText() {
            // @todo check if in org / team context to display user name
            return this.messagePublishedTime;
        },
        messageParticipant() {
            return this.getChatRoomParticipant(this.message.user_id);
        },
        lastSeenText() {
            return this.isMessageUserOnline ? 'Online' : 'Last Seen: ' + this.messageParticipant?.last_seen_at ?? 'unknown';
        },
        hasReactions() {
            if (typeof this?.message?.reactions == 'undefined') {
                return false;
            }
            return this.message.reactions.length > 0;
        },
        groupedReactions() {
            if (typeof this?.message?.reactions == 'undefined') {
                return [];
            }
            const reactions = [...this.message?.reactions]; // Assuming `reactions` is your array of reaction objects
            const grouped = reactions.reduce((acc, reaction) => {
                const { emoji } = reaction;
                if (!acc[emoji]) {
                    acc[emoji] = { count: 0, reactions: [] };
                }
                acc[emoji].count++;
                acc[emoji].reactions.push(reaction);
                return acc;
            }, {});
            return grouped;
        },
        isCurrentMessageSelectedContext() {
            return this.message.id === this.selected_message_context?.id ?? 0;
        },
        isDeleted() {
            // Perhaps add contextual deletes
            return !_.isNull(this.message?.deleted_at ?? null);
        },
        messageHtmlContent() {
            return this.message?.message ?? 'no content';
        },
        messagePublishedTime() {
            return formatISO9075(parseISO(this.message.created_at));
        },
        getProfileImage() {
            return '/empty.png';
        },
        getAboveProfileImageText() {
            if (this.isCurrentUser) {
                return this.core.full_name;
            } else {
                let user_object = this.all_users.find((user) => {
                    return user.id == this.message.user_id;
                });
                return `${user_object.name}`;
            }
        },
        getChatUserProfileImage() {
            const chatRoom = this.getCurrentChatRoom;
            if (_.isEmpty(chatRoom)) {
                return '/img/user.png';
            }
            let user_object = this.all_users.find((user) => {
                return user.id == this.message.user_id;
            });
            return user_object.avatar_src;
        },
        getChatUser() {
            const chatRoom = this.getCurrentChatRoom;
            if (_.isEmpty(chatRoom)) {
                return false;
            }
            if (!this.isGroupChat) {
                let user_object = this.all_users.find((user) => {
                    return user.id == chatRoom?.linkable?.id;
                });
                return user_object;
            } else {
                let user_object = this.all_users.find((user) => {
                    return user.id == this.message.user_id;
                });
                return user_object;
            }
        },
        getChatUserOnlineIndicator() {
            return 'online';
        },
        getHorizontalAlignProp() {
            // https://bootstrap-vue.org/docs/components/layout#columns-b-col
            // https://bootstrap-vue.org/docs/components/layout#alignment
            return this.isCurrentUser ? 'end' : 'start';
        },
        getHorizontalAlignPropReadReceipt() {
            return this.isCurrentUser ? 'start' : 'end';
        },
        isCurrentUser() {
            return this.core.id === this.message.user_id;
        },
        isGroupChat() {
            return !this.getCurrentChatRoom?.is_direct ?? false``;
        },
        messageReadBy() {
            if (!this.isChatMessageRead) {
                return [];
            }
            return this?.message?.read_receipts.filter((m) => m.user_id !== this.message.user_id);
        },
        isMessageUserOnline() {
            return this.getChatRoomUser(this.message.user_id);
        },
        isChatMessageRead() {
            if (this.isGroupChat) {
                if (this?.message?.read_receipts === undefined) {
                    return false;
                } else {
                    let receipts = this?.message?.read_receipts.filter((m) => m.user_id !== this.message.user_id);
                    return receipts.length > 0;
                }
            } else {
                if (typeof this?.message?.read_receipts == 'undefined') {
                    return false;
                } else {
                    return this?.message?.read_receipts?.length > 0;
                }
            }
        },
        aboveMessageTextClassName() {
            return this.isCurrentUser ? 'msg-description text-muted text-right' : 'msg-description text-muted text-right';
        },
        avatarOnlineIndicatorClassName() {
            return this.isMessageUserOnline ? 'bg-success rounded-pill text-primary' : 'bg-dark rounded-pill text-primary';
        },
        messageClassName() {
            return this.isCurrentUser ? 'chat-message self' : 'chat-message other';
        },
        messageContainerClassName() {
            return this.isCurrentUser ? 'chat-message-container self' : 'chat-message-container other';
        },
    },
    methods: {
        ...chatMethods,
        togglePopover(emoji, targetId) {
            console.log('targetId', targetId);
            // Set the popover target to the clicked badge ID
            this.popoverTarget = targetId;
            // Toggle the visibility of the popover
            this.showPopover = !this.showPopover;
            if (this.showPopover) {
                // Set the selected emoji tab when popover is shown
                this.selectedEmojiTab = emoji;
            }
        },
        toggleReadPopover() {
            this.showReadPopover = !this.showReadPopover;
        },
        popoverHidden() {
            // Reset selected tab when popover is hidden
            this.selectedEmojiTab = null;
            this.popoverTarget = null;
        },

        formatReadByDate(date) {
            return formatRelative(parseISO(date), new Date());
        },
        formatDate(date) {
            return formatISO9075(parseISO(date));
        },
        findEmoji(emoji) {
            return emojisDefault.find((emo) => emo.aliases.includes(emoji))?.data ?? 'No Emoji';
        },
        findUser(userId) {
            let user_object = this.all_users.find((user) => {
                return user.id == userId;
            });
            return user_object;
        },
        showContextMenu(event) {
            if (!this.isCurrentUser) {
                return false;
            }
            event.preventDefault();
            this.contextMenuX = event.clientX;
            this.contextMenuY = event.clientY;
            this.toggleContextMenuVisible(this.message);
        },

        hideContextMenu() {
            this.isContextMenuVisible = false;
        },

        handleContextMenuAction(action) {
            if (action == 'delete') {
                this.handleContextMenuDelete(this.message);
            }
            // Handle context menu action
            this.hideContextMenu();
        },
        toggleEmojiPicker() {
            this.toggleEmojiPickerVisible(this.message);
        },
        async handleEmojiSelect(emoji) {
            // Handle emoji selection
            await this.reactToMessage({ message_id: this.message.id, emoji }).then(() => {
                // This behaves a bit funny because it's designed to be event driven
                this.onChatMessageReaction({
                    userId: this.core.id,
                    reactions: [
                        {
                            message_id: this.message.id,
                            emoji: emoji.aliases[0],
                            user_id: this.core.id,
                        },
                    ],
                });
                this.toggleEmojiPickerVisible();
            });
            // You can append the selected emoji to the message content or perform any other action here
        },
    },
};
</script>

<style lang="scss">
.msg-description {
    margin: 1em 1em;
}

.text-right {
    text-align: right;
}

.chat-message {
    display: flex;
    border: 1px solid #000;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    padding: 15px 0px 15px 0px;

    p {
        font-size: 0.7rem;
        margin-left: 1em;
    }

    &.self {
        background: lightblue;
        text-align: left;
        background: #eff2f7;
        border-color: #eff2f7;
        align-content: flex-start;
        border-bottom-right-radius: 0;
    }

    &.other {
        align-content: flex-start;
        background: rgba(85, 110, 230, 0.1);
        border-color: rgba(85, 110, 230, 0.1);
        border-bottom-left-radius: 0;
    }
}

.read {
    color: rgba(85, 110, 230, 1);
}

.message-content > p {
    margin-bottom: 0px;
}

.message-emoji-list {
    list-style: none;

    li {
        display: inline;
        padding: 5px;
        font-size: 1em;
    }
}

.context-menu {
    position: fixed;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.context-menu ul {
    list-style: none;
    margin: 0;
    padding: 5px 0;
}

.context-menu li {
    padding: 8px 20px;
    cursor: pointer;
}

.context-menu li:hover {
    background-color: #f0f0f0;
}

.emoji-invoker {
    position: relative;
    bottom: 0rem;
    margin-right: 2px;
    width: 1.2rem;
    height: 1.2rem;
    /* padding-top: 20px !important; */
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
    padding: 0;
    background: transparent;
    border: 0;
}

.emoji-invoker:hover {
    transform: scale(1.1);
}

.emoji-invoker>svg {
    fill: #272727;
}

.chat-message-drop-dots {
    position: absolute;
    right: 1em;
    top: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
