<template>
    <b-sidebar id="sidebar-1"  header-class="custom-chat-class" sidebar-class="chat-sidebar" :title="title" lazy width="60rem" backdrop shadow="lg">
        <div class="px-3     py-2">
            <ChatComponent @setTitle="setTitle" />
        </div>
    </b-sidebar>
</template>

<script>
import ChatComponent from '../../../router/views/menu-pages/chatComponent.vue';
export default {
    components:{
        ChatComponent,
    },
    data:() => ({
        title: '',
    }),
    computed:{},
    methods:{
        setTitle(value){
            this.title = value;
        }
    },
    mounted(){},
}
</script>

<style>
    .chat-sidebar{
        border-right: 1px solid grey;
        margin-left: 250px;
        margin-top: 45px;
    }

    .b-sidebar-outer > .b-sidebar-backdrop{
        margin-left: 250px;
        margin-top: 45px;
        background-color: rgb(20 88 155) !important;
    }

    .custom-chat-class{
        font-size: 0.85rem !important;
        padding: 10px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-right: 0 !important;
        border-bottom: 1px solid rgb(202, 202, 202);
    }
    .custom-chat-class > #sidebar-1___title__{
        font-weight: 500;
    }

    .custom-chat-class > button{
        font-size: 1rem !important;
        border: 0 !important; 
    }
</style>