<template>
    <b-modal size="xl" id="searchModal">
        <b-row>
            <b-col cols="6">
                <b-button><i class="fas fa-search"></i> Search</b-button>
                &nbsp;
                <b-form-input style="width: unset; display: inline;" placeholder="Enter Search Keywords" ></b-form-input>
            </b-col>
            <b-col cols="12">
                <b-form-radio-group
                    id="btn-radios-1"
                    class="check-group .btn-group"
                    v-model="selected"
                    :options="options"
                    buttons
                    name="radios-btn-default"
                ></b-form-radio-group>
            </b-col>
            
        </b-row>
    </b-modal>
</template>

<script>
export default {
    data:() => ({
        selected: 'all',
        options:[
            {
                text: 'All', value: 'all'
            },
            {
                text: 'People', value: 'all'
            },
            {
                text: 'Objectives', value: 'all'
            },
        ]
    }),
    computed:{},
    methods:{},
    mounted(){},
}
</script>

<style>
#searchModal>.modal-dialog>.modal-content{
    min-height: 20rem !important;
}
</style>