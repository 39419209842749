<template>
  <!-- Two-way Data-Binding -->
  <b-row>
    <b-col cols="11" style="">
      <quill-editor
        ref="myQuillEditor"
        v-model="content"
        :options="editorOption"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
      />
    </b-col>
    <b-col cols="1" align-self="center">
      <b-button
        @click="onSendChatMessage(content)"
        block
        variant="primary"
        style="width: 90%; height: 60px; font-size: 2em;"
      >
        <font-awesome-icon icon="fa-solid fa-paper-plane" />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
// You can also register Quill modules in the component
import { adminComputed, chatComputed, chatMethods } from '@/state/helpers';
import { quillEditor } from 'vue-quill-editor';
import Quill from 'quill';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import QuillEmoji from 'quill-emoji';

export default {
  props: {
    roomId: Number || null,
  },
  components: {
    quillEditor,
  },
  data() {
    return {
      content: '',
      editorOption: {
        // Some Quill options...
        theme: 'snow', // or 'bubble'
        placeholder: 'Enter Message Here',
        modules: {
          // Include quill-emoji module
          'emoji-toolbar': true,
          'emoji-textarea': false,
          'emoji-shortname': true,
          toolbar: [
            [{ header: [false, 1, 2] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            ['emoji'], // Add emoji button to the toolbar
            ['link', 'code'],
            ['clean'],
          ],
          keyboard: {
            bindings: {
              shift_enter: {
                key: 13,
                shiftKey: true,
                handler: (range, ctx) => {
                  console.log(range, ctx); // if you want to see the output of the binding
                  this.editor.insertText(range.index, '\n');
                },
              },
              enter: {
                key: 13,
                handler: () => {
                  if (this.content.length > 0) {
                    this.onSendChatMessage(this.content);
                  }
                },
              },
            },
          },
        },
      },
    };
  },
  methods: {
    ...chatMethods,
    onEnterKey(event) {
      console.log({ event });
      if (event.shiftKey === false) {
        event.preventDefault(); // Prevent default behavior of newline on Enter press
      }
    },
    onEditorBlur(quill) {
      console.log('editor blur!', quill);
    },
    onEditorFocus(quill) {
      console.log('editor focus!', quill);
    },
    onEditorReady(quill) {
      console.log('editor ready!', quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log('editor change!', quill, html, text);
      this.content = html;
    },
    async onSendChatMessage(content) {
      //eslint-disable-next-line
      return await this.sendNewMessage(content).then((response) => {
        this.content = ''; // Clear the message input
      });
    },
  },
  computed: {
    ...adminComputed,
    ...chatComputed,
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  mounted() {
    Quill.register('modules/emoji', QuillEmoji);
  },
};
</script>
style
<style>
/* .ql-editor {
  overflow-y: scroll;
  min-height: 150px;
} */
.ql-container {
  min-height: 8rem !important;
  max-height: 8rem !important;
  overflow-y: scroll;
  margin-bottom: 10px;
}
</style>
