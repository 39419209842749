<template>
    <div>
        <!-- <div class="ocean">
  <div class="wave"></div>
  <div class="wave wave2"></div>
</div> -->
        <div class="p-4 m-4 text-center" style="border-radius: 2rem;">
            <div style="width: 100%;" class="mb-4 pb-5">
                <img style="filter: invert(100%);" src="@/assets/images/logo-light.png" alt width="900" height="220" />
            </div>
            <div>
                <Loader class="mb-4" :loading="true" />
            </div>
        </div>
    </div>
</template>

<script>
import Loader from './loader.vue'
export default {
    data:() => ({}),
    components:{
        Loader,
    },
    computed:{},
    methods:{},
    mounted(){},
}
</script>

<style scoped>
.ocean {
  height: 5%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: radial-gradient(rgba(255,254,234,1) 0%, rgba(255,254,234,1) 35%, #B7E8EB 100%);
}

.wave {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
  position: absolute;
  width: 6400px;
  top: -198px;
  left: 0;
  height: 198px;
  transform: translate(0, 0, 0);
  animation: wave 7s ease infinite;
}

.wave:nth-of-type(2) {
  top: -168px;
  animation: swell 4s ease infinite;
  opacity: 1;
}

@keyframes wave {
  0% { margin-left: 0; }
  100% { margin-left: -1600px;}
}
@keyframes swell {
  0%, 100% {
    transform: translate(0, -30px);
  }
  50% {
    transform: translate(0, 5px);
  }
}

</style>