<template>
    <div class="user-list-card set-grey-border">
        <div style="padding: 0.4rem; padding-bottom: 0px; padding-top: 0px;">
            <div class="d-flex flex-wrap" style="width: 100%;">
                <div class="me-2">
                    <h6 class="sub-heading-text mb-0 mt-2">
                        <span>Teams</span>
                    </h6>
                </div>
            </div>
        </div>
        <span v-show="is_loading == false && list_data.length == 0" class="text-secondary px-1">
            No Teams Found
        </span>
        <webix-ui class="userListComponent" :config="ui" v-model="list_data"></webix-ui>
        
        <b-overlay no-wrap :show="is_loading"></b-overlay>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import { levelMethods, levelComputed } from '@/state/helpers';
export default {
    data:() => ({
        is_loading: false,
    }),
    watch:{
        //* watch variables that need to load data
        current_level_id(){
            this.is_loading = true;
            this.fetchData();
        },
        selected_user_id(){
            this.is_loading = true;
            this.fetchData();
        },

        //* ----------------
        list_data(){
            const is_id_in_array = this.list_data.some(item => item.id == this.selected_team_id);
            if(!is_id_in_array){
                this.selectLevelTeam(-1);
            }

        }
    },
    computed:{
        ...levelComputed,
        list_data(){
            return this.level_teams != null
                ? this.level_teams : [];
        },
        ui(){
            let el = this;
            return {
                id: 'teamsList',
                view: 'tree',
                css: 'scroll-fix',
                on:{
                    onItemClick(id){
                        el.handleTeamSelect(id);
                        //el.selectLevelTeam(id);
                    }
                },
                //eslint-disable-next-line
                template(object, common){
                    let class_names = 'normal_text';
                    if(object.id == el.selected_team_id){
                        class_names += ' text-primary font-weight-bold'
                    }
                    return `
                    <div class="${class_names}">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="users" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-users me-1"><path fill="currentColor" d="M144 160c-44.2 0-80-35.8-80-80S99.8 0 144 0s80 35.8 80 80s-35.8 80-80 80zm368 0c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM416 224c0 53-43 96-96 96s-96-43-96-96s43-96 96-96s96 43 96 96zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" class=""></path></svg>
                    ${object.name}
                    </div>
                    `;
                },
            }
        }
    },
    methods:{
        ...levelMethods,
        fetchData: debounce( function (){
            let params = {
                level_id: this.current_level_id,
            }
            if(this.selected_user_id != null){
                params['user_id'] = this.selected_user_id;
            }

            this.loadLevelTeams(params).then(()=>{
                this.is_loading = false;
            })
        }, 100),
        handleTeamSelect(id){
            if(id == this.selected_team_id){
                this.selectLevelTeam(-1);
            }
            else{
                this.selectLevelTeam(id);
            }
            //this.selectLevelTeam(id);
            this.$nextTick(()=>{
                window.webix.$$('teamsList').refresh();
            })
        },
    },
    mounted(){
        this.fetchData();
    },
}
</script>

<style>

</style>