<template>
    <b-modal id="addJobModal" hide-footer title="Add Company Job" style="height: 100%;">
        <template #modal-header style="background-color: #EBEDF0;">
            <div class="w-100 d-flex" >
                <h6 class="m-1" style="font-weight: bold;"> Add Company Job</h6> <br>   
                <span v-if="$hasPerm('Power User')" class="form-check form-switch me-3 ms-auto" style="font-size: 10px;">
                </span>
                <font-awesome-icon @click="$bvModal.hide('addJobModal')" style="cursor: pointer; padding-top: 2px;" icon="fa-times"></font-awesome-icon>

            </div>
        </template>
        <b-overlay :show="is_loading">
        <div class="mt-0">
            <div>
                <div>
                    <b-form @submit="onSubmit">
                    <b-form-group class="label" id="input-group-1" label="Job Title:" label-for="input-1" size="sm">
                        <b-form-input
                        id="input-1"
                        size="md"
                        v-model="form.title"
                        placeholder="Enter Job Title"
                        required
                        ></b-form-input>
                    </b-form-group>
                    <b-button type="submit" class='ms-auto mt-1' style="float: right" variant='success'>Submit</b-button>
                    </b-form>
                </div>
            </div>
        </div>
        </b-overlay>
    </b-modal>
</template>

<script>
import { levelComputed, levelMethods, activityComputed, activityMethods, profileComputed, profileMethods, peopleComputed, peopleMethods } from '@/state/helpers'
// import { format, parseISO } from 'date-fns';
// import Swal from 'sweetalert2';
import dateMixin from '@/mixins/dateMixin.js'
export default {
mixins:[ dateMixin ],
props:{
},
data:() => ({
    is_loading: false,
    users: [],
    filter_input: '',
    assigned_level_id: -1,
    owner_query: '',
    user_query: '',
    form: {
        id: -1,
        title: '',
    },
    show: true,
}),
computed:{
    ...levelComputed,
    ...activityComputed,
    ...profileComputed,
    ...peopleComputed,
},
methods:{
    ...activityMethods,
    ...profileMethods,
    ...levelMethods,
    ...peopleMethods,
    onSubmit(event) {
        event.preventDefault();
        this.is_loading = true
        this.$nextTick(() => {
            let data = [ this.form ]
            let rows_to_save = [];
            data.forEach(item => {
                let params = {
                    id: -1,
                    title: item.title,
                    level_id: this.company_id,
                };
                rows_to_save.push(params);
            });
        
            this.saveHiringJob(rows_to_save)
            .then(()=>{
                this.loadCompanyJobs(this.company_id)
                .then(()=>{
                    this.$emit('saved')
                    this.$nextTick(() => {
                        this.form.title = ''
                        this.is_loading = false
                    })
                })
            })
        }) 
    },
},
beforeDestroy() {
    this.modal_payload = {};
    this.is_loading = false;
},
mounted(){ 
    this.is_loading = false
},
}
</script>
<style>
</style>