<template>
        <b-modal id="hierarchyModal" hide-footer :title="modalTitle">
            <div class="modal-title" v-if="is_user_modal == false">
                Selected Objective: <span style="font-weight: bold;" class="text-primary">{{payload.name}}</span>
            </div>
            <!-- <b-alert v-if="is_user_modal == false" fade class="hierarchyModalAlert" size="sm" :show="show_alert" dismissible>
                {{alertMessage}}
            </b-alert> -->
            <b-overlay :show="is_loading">
                <webix-ui style="height: 100%;" :config="ui" v-model="level_data" ></webix-ui>
            </b-overlay>
        </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import { levelComputed, activityComputed } from '@/state/helpers'
import _ from 'lodash'
export default {
    props:{
        is_user_modal:{
            type: Boolean,
            default: false,
        },
        payload:{
            required: true,
        },
        show_all_levels: Boolean,
        //* apply custom rule for selecting hierarchy on top level (only show other comany levels)
        company_level:Boolean,
        company_level_id:{
            default: false,
        }
    },
    data:() => ({
        show_alert: true,
        is_loading: false,
        parent_objective: null,
    }),
    /*watch:{
        level_data(newValue){
            if(newValue.length > 0){
                window.webix.$$('treeModal').openAll();
            }
        }
    },*/
    computed:{
        ...levelComputed,
        ...activityComputed,
        alertMessage(){
            let text = '';
            if(this.show_all_levels ){
                text = "Showing levels for parent company."
            }
            else if(this.payload.parent_id == null){
                text = "Only parent levels are available."
            }
            else{
                text = 'Only showing available child levels.'
            }
            return text;
        },
        ui(){
            let el = this;
            //eslint-disable-next-line
            let current_id = this.payload.level_id
            return {
                css: "webixModal",
                id: 'treeModal',
                view: 'tree',
                autoheight: true,
                template:"{common.icon()}#value#",
                minHeight: 500,
                maxHeight: 700,
                ready:function(){
                    setTimeout(()=>{
                        let item = this.getItem(el.payload.level_id);
                        while(item.$parent != 0){
                            /* open parent, then set current item */
                            this.open(item.$parent);
                            item = this.getItem(item.$parent);
                        }
                        this.addCss(el.payload.level_id, 'selectable_hierarchy_row')

                        //! bleh -- :(
                        //* show only the related levels
                        //todo - need to find parent first and use that id-

                        //* linked level is a COMPANY level
                        // if(el.payload.parent_id == null){
                        //     console.log('COMPANY LEVEL LINK')
                        // }
                        // else{
                        //     let index = _.findIndex(el.activity_data.objectives, {id: el.payload.parent_id});
                        //     el.parent_objective = el.activity_data.objectives[index];
                        //     this.filter(function(obj) {
                        //         if(obj.id != undefined){
                        //             return (
                        //                 obj.id == el.parent_objective.level_id
                        //             );
                        //         }
                        //     });
                        // }
                        // else{
                        //     el.parent_objective = el.payload;
                        //     /*this.filter(function(obj) {
                        //         if(obj.id != undefined){
                        //             return (
                        //                 obj.id == el.parent_objective.level_id
                        //             );
                        //         }
                        //     });*/
                        // }

                        

                        // if(el.payload.parent_id != null){
                        //     let id_array = [];
                        //     el.levels.forEach( level => {
                        //         if(level.parent_id == el.parent_objective.level_id){
                        //             this.addCss(level.id, "selectable_hierarchy_row")
                        //             id_array.push(level.id);
                        //         }
                        //     });
                        //     let remove_id_array = [];
                        //     el.levels.forEach( level => {
                        //         id_array.forEach( id => {
                        //             if(id == level.parent_id){
                        //                 remove_id_array.push(level.id);
                        //             }
                        //         })
                        //     });
                        //     this.remove(remove_id_array);
                        // }
                        // if(this.show_all_levels == false){
                        //     this.select(current_id);
                        // }
                        // if(el.company_level_id){
                        //     this.filter(function(obj) {
                        //         if(obj.id != undefined){
                        //             return (
                        //                 obj.id == el.company_level_id
                        //             );
                        //         }
                        //     });
                        // }
                        //this.openAll();
                    }, 250);
                },
                on:{
                    //eslint-disable-next-line
                    onItemClick(id){
                        //! HOTFIX - just comment it out bro!
                        //TODO - implement this again after finding clarity 
                        // let selected_item = this.getItem(id);
                        // let parent_item = this.getItem(el.parent_objective.level_id);
                        
                        //! yikes - give these 2 variables data so that the isValidSelection doesn't complain
                        let selected_item = this.getItem(id);
                        let parent_item = this.getItem(id);

                        //! no idea what show_all_levels was for.. maybe for the user level selection ?? Bad Dylan, bad!
                        if(el.show_all_levels){
                            if(el.payload.is_new){
                                el.$emit('updatedNewID', id);
                            }
                            else{
                                el.$emit('selectedNewID', id);
                            }
                            
                        }
                        else{
                            if(el.isValidSelection(selected_item.$level, parent_item.$level)){
                                if(el.payload.$count > 0){
                                    Swal.fire({
                                        title: "Please note:",
                                        text: "All sub-objectives under this Objective will have their positions reset. Please review all its sub-objective positions.",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#34c38f",
                                        cancelButtonColor: "#f46a6a",
                                        confirmButtonText: "Yes",
                                    }).then((result) => {
                                        if (result.value) {
                                            //el.is_loading = true;
                                            el.$emit('selectedNewID', id);
                                        }
                                    });
                                }
                                else{
                                    if(el.payload.is_new){
                                        el.$emit('updatedNewID', id);
                                    }
                                    else{
                                        el.$emit('selectedNewID', id);
                                    }
                                }
                                
                            }
                            else{
                                Swal.fire({
                                    icon:'error',
                                    title:"This level can't be selected.",
                                })
                            }
                        }
                    },
                }
            }
        },
        level_data(){
            var data = _.cloneDeep(this.levels);
            if(data == null){
                data = [];
            }
            else{
                data.forEach((element) => {
                    element["parent"] = element.parent_id;
                    element["value"] = element.name;
                    element['data'] = [];
                });

                data.forEach((ele) => {
                    let parent_id = ele.parent_id;
                    if (parent_id == null) {
                    //* Parent item -- do nothing
                    } else {
                    //* If element is a child element, push to children array.
                    data.forEach((d) => {
                        if (d.id === parent_id) {
                            let childArray = d.data;
                            if (!childArray) {
                                childArray = [];
                            }
                            childArray.push(ele);
                            d.data = childArray;
                        }
                    });
                    }
                });
                data = data.filter((ele) => ele.parent_id != ele.id);
                //* Remove duplicate elements
                data = data.filter((ele) => ele.parent_id == null);
                //*Set parent root as expanded;

                //* only show the top level hierarchy levels if the modal was opened at the comany level and the selected objective is a sub objective
                //! temp removed
                /*if(this.company_level && this.payload.parent_id == null){
                    data.forEach( item => {
                        item.data = [];
                    })
                }*/
            }
            return data;
        },
        modalTitle(){
            if(this.is_user_modal){
                return 'Set User Structure Level'
            }
            else{
                let title = `Update Linked Structure`; //${this.payload.modal_type}
                return title;
            }
        },
    },
    methods:{
        //eslint-disable-next-line
        isValidSelection(clicked_level, parent_level){
            return true;
            //TODO - remember and add comments to whatever these if statements are doing -- for now just return true
            // if(this.payload.parent_id == null){
            //     return true;
            // }
            // if(clicked_level == parent_level){
            //     return true;
            // }
            // else if(clicked_level > parent_level && clicked_level - parent_level == 1){
            //     return true;
            // }
            // else{
            //     return false;
            // }
        }
    },
    mounted(){
        console.log(JSON.parse(JSON.stringify(this.payload)))
        /*this.$nextTick(()=>{
            window.webix.$$('treeModal').openAll();
        })*/
    },
}
</script>
<style>
    .hierarchyModalAlert{
        padding: 0.4rem;
    }
    .hierarchyModalAlert .close{
            padding: 0;
            font-size: 20px;
        }
    .webixModal .webix_selected{
        /*background-color: #daecff !important;*/
        text-decoration: underline black ;
    }
    .show_hierarchy_modal{
        cursor: pointer;
        color: transparent;
    }
    .show_hierarchy_text:hover .show_hierarchy_modal{
        color: dodgerblue;
    }
    /*.show_hierarchy_modal:hover{
        color: dodgerblue;
    }*/

    .selectable_hierarchy_row{
        background-color:rgb(220, 255, 243);
    }
</style>