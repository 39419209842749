<template>
    <b-button :variant="variant" :size="size" @click="handleShowGuideline()">
        <font-awesome-icon class="" icon="fa-solid fa-question-circle" />
    </b-button>
</template>

<script>
import { guidelineMethods } from '@/state/helpers';
export default {
    data:() => ({}),
    props:{
        guideline_key: {
            type: String,
            required: true
        },
        variant:{
            default: () => {
                return 'outline-info'
            }
        },
        size:{
            default: () => {
                return 'sm'
            }
        }
    },
    computed:{},
    methods:{
        ...guidelineMethods,
        handleShowGuideline(){
            this.setSelectedGuidelineID({
                type: this.guideline_key
            });
        },
    },
    mounted(){},
}
</script>

<style>

</style>