<template>
    <b-modal no-close-on-backdrop hide-footer title="Personal Details" id="newUserModal" size="lg">
        <b-form v-if="!is_loading && !is_saving" autocomplete="off" class="userForm mt-3">
            <b-row class="mb-2">
                <b-col cols="6">
                    <label for="">First Name</label>
                    <b-form-input autocomplete="never" v-model="first_name"></b-form-input>
                </b-col>
                <b-col cols="6">
                    <label for="">Surname</label>
                    <b-form-input autocomplete="never" v-model="last_name"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6">
                    <label for="">Email</label>
                    <b-form-input autocomplete="never" type="email" id="newUserE" v-model="email"></b-form-input>
                </b-col>
                <b-col cols="6">
                    <label style="width: 100%;" for="">Start Date</label>
                    <b-form-input style="width: 100%;" type="date" id="date" v-model="start_date" class=" mb-2"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6">
                    <label for="">Job Title</label>
                    <b-row>
                        <b-col cols="8">
                            <JobTypeahead v-if="!is_loading"
                                :isNewUser="true"
                                :enableEdit="true"
                                :autofocus="false"
                                @returnJob="handleTypeaheadUpdate"/>
                        </b-col>
                        <b-col cols="4">
                            <b-button variant="primary" @click="showAddJobModal" title="Add Job">
                                <i class="fas fa-pen"></i>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="6">
                    <label style="width: 100%;" for="">Assigned Organisational Structure</label>
                    <b-row>
                        <b-col cols="8">
                            <b-form-input readonly style="width: 100%;" type="text" id="level" v-model="getLevelForID" class=" mb-2"></b-form-input>
                        </b-col>
                        <b-col cols="4">
                            <b-button variant="primary" @click="showHierarchyModal">
                                <i class="fas fa-pen"></i>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="mt-1" cols="6">
                    <label for="">Reports To:</label>
                    <b-row>
                        <b-col cols="10">
                            <vue-typeahead-bootstrap
                                ref="userSearch"
                                :data="users"
                                prepend="Reports to:"
                                v-model="filter_input"
                                placeholder="Search name/email"
                                @hit="selectedOwner"
                                showOnFocus
                            ></vue-typeahead-bootstrap>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-form>
        <b-alert :show="is_loading && has_mounted">
            Loading company jobs
        </b-alert>
        <b-alert variant="success" :show="is_saving">
            Saving Employee Data
        </b-alert>
        <HierarchyModal :payload="hierarchy_payload"
            is_user_modal
            show_all_levels 
            v-if="show_hierarchy_modal"
            @selectedNewID="updateLevelId"
        />
        <addJobModal @saved="refreshJobList"/>
        <b-row class="mt-2">
            
            <b-col cols="12">
                <b-overlay :show="is_saving">
                    <div style="float: right">
                        <b-button @click="$bvModal.hide('newUserModal')" class="me-2">Cancel</b-button>
                        <b-button @click="save()" variant="success">Create New User</b-button>
                    </div>
                </b-overlay>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import JobTypeahead from '../people/components/employees/changeJobCard.vue'
import { format } from "date-fns";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import {
    peopleComputed,
    adminComputed,
    adminMethods,
    levelComputed,
    levelMethods,
    peopleMethods,
    profileComputed,
    profileMethods
} from "@/state/helpers";
import _ from 'lodash';
import Swal from 'sweetalert2'
import HierarchyModal from '@/components/objectives/components/misc/hierarchyModal.vue'
import addJobModal from '@/components/menu/addJobModal.vue'
export default {
    components:{
        HierarchyModal,
        JobTypeahead,
        addJobModal,
        VueTypeaheadBootstrap
    },
    data:() => ({
        has_mounted: false,
        users: [],
        filter_input: '',
        first_name: null,
        last_name: "",
        email: "",
        job_title: "",
        start_date: '',
        level_id: null,
        reports_to_user: null,
        new_job_id: -1,
        assigned_level_id: -1,
        show_job_modal: false,
        show_hierarchy_modal: false,
        hierarchy_payload:{
            level_id: -1,
            parent_id: null
        },
        is_loading: false,
        is_saving: false,
    }),
    computed:{
        ...peopleComputed,
        ...levelComputed,
        ...profileComputed,
        ...adminComputed,
        getLevelForID(){
            let name = 'N/A';
            if(this.assigned_level_id){
                let index = _.findIndex(this.levels, {id:this.assigned_level_id})
                if(index != -1){
                    name = this.levels[index].name;
                }
            }
            return name;
        }
    },
    methods:{
        ...peopleMethods,
        ...levelMethods,
        ...profileMethods,
        ...adminMethods,
        refreshJobList() {
            this.is_loading = true
            this.$nextTick(() => {
                this.is_loading = false
                this.$bvModal.hide('addJobModal')
                // let job = this.companyJobs.find( job => {
                //     return job.title == name
                // });
                // console.log(job)
                // setTimeout(() => {
                //     if(job) {
                //         this.job_title = job.title;
                //         console.log(this.job_title)
                //         this.new_job_id = job.id;
                //         console.log(this.new_job_id)
                //     }
                // }, 5000) 
            })
            //refresh the available jobs
        },
        validateEmail(email) {
           return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        },
        handleTypeaheadUpdate(job){
            console.log(job)
            this.job_title = job.text;
            this.new_job_id = job.id;     
        },
        showAddJobModal(){
            //* NEED TO SET modal_payload here 
            this.show_job_modal = true;
            //! ffs -> nextTick not working :(
            setTimeout(()=>{
                this.$bvModal.show('addJobModal');
                //* execute cleanup actions when modal is closed
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "addJobModal") {
                        //* hide modal with prop to retrigger mounted hook (with v-if) when showing the modal
                        this.show_job_modal = false;
                    }
                });
            }, 1)
        },
        showHierarchyModal(){
            //* NEED TO SET modal_payload here 
            this.hierarchy_payload.level_id = this.assigned_level_id;
            this.show_hierarchy_modal = true;
            //! ffs -> nextTick not working :(
            setTimeout(()=>{
                this.$bvModal.show('hierarchyModal');
                //* execute cleanup actions when modal is closed
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "hierarchyModal") {
                        //* hide modal with prop to retrigger mounted hook (with v-if) when showing the modal
                        this.show_hierarchy_modal = false;
                        this.hierarchy_payload.level_id = -1;
                        //* reset payload
                        //this.modal_payload = null;
                    }
                });
            }, 1)
        },
        updateLevelId(new_id){
            this.assigned_level_id = Number(new_id);
            this.$bvModal.hide('hierarchyModal')
        },
        save(){
            if(!this.first_name){
                Swal.fire({ icon:'warning', title:'Enter Name Field', toast: true, position: 'top', showConfirmButton: false, timer: 3000, timerProgressBar: true, })
                return;
            }
            if(!this.reports_to_user){
                Swal.fire({ icon:'warning', title:'Please select a user to report to', toast: true, position: 'top', showConfirmButton: false, timer: 3000, timerProgressBar: true, })
                return;
            }
            if(this.checkEmail()) {
                Swal.fire({ icon:'warning', title:'Email Already Being Used', toast: true, position: 'top', showConfirmButton: false, timer: 3000, timerProgressBar: true, })
                return;
            }
            if(!this.validateEmail(this.email)) {
                Swal.fire({ icon:'warning', title:'Please Enter Valid email', toast: true, position: 'top', showConfirmButton: false, timer: 3000, timerProgressBar: true, })
                return;
            }
            if(this.email === ''){
                Swal.fire({ icon:'warning', title:'Enter Email Field', toast: true, position: 'top', showConfirmButton: false, timer: 3000, timerProgressBar: true, })
                return;
            }
            if(this.last_name === ''){
                Swal.fire({ icon:'warning', title:'Enter Surname Field', toast: true, position: 'top', showConfirmButton: false, timer: 3000, timerProgressBar: true, })
                return;
            }
            if(this.new_job_id == -1){
                Swal.fire({ icon:'warning', title:'Job Title required', toast: true, position: 'top', showConfirmButton: false, timer: 3000, timerProgressBar: true, })
                return;
            }
            this.createEmployee()
            .then(()=>{
                this.$bvModal.hide('newUserModal');
                this.$eventHub.$emit('savedNewUser');
            })
        },
        checkEmail() {
            let emails = []
            if(this.levelPeopleData.archived.length != 0) {
                this.levelPeopleData.archived.forEach(employee => {
                    emails.push(employee.email)
                })
            }
            if(this.levelPeopleData.active.length != 0) {
                this.levelPeopleData.active.forEach(employee => {
                    emails.push(employee.email)
                })
            }
            if(emails.includes(this.email)) {
                return true
            } else {
                return false
            }
        },
        createEmployee(){
            return new Promise((resolve,reject) => {
                this.is_saving = true;
                var params = {
                    id: -1,
                    level_id: this.assigned_level_id,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    reports_to_user_id: this.reports_to_user,
                    email: this.email,
                    start_date: this.start_date != '' ? this.start_date : new Date(),
                    job_id: this.new_job_id,
                };
                this.saveEmployee(params)
                .then((id)=>{
                    this.loadEmployeesData(this.selected_level.id)
                    .then(()=>{
                        this.is_saving = false;
                        this.activeEmployeeId = id;
                        //this.$eventHub.$emit('setActiveEmployee', res);
                        resolve();
                    });
                    this.loadAllUsers();
                })
                .catch((err) => {
                    console.log(err)
                    this.is_saving = false;
                    reject();
                })
            })
        },
        populateList(){
            let users = [];
            this.all_users.forEach( user =>{
                users.push(`${user.name} | ${user.email}`)
            });
            this.users = users;
        },
        selectedOwner(item) {
            let email = item.split('|')[1];
            let email_string = email.trim();
            let user = this.all_users.find( item => {
                return item.email == email_string
            });
            this.reports_to_user = user.id
        }, 
    },
    mounted(){
        this.populateList()
        this.loadPeopleData(this.company_id)
        console.log(this.companyJobs)
        this.start_date = format(new Date(), 'yyyy-MM-dd')
        if(this.companyJobs.length == 0){
            this.is_loading = true;
            this.loadCompanyJobs(this.company_id)
            .then(()=>{
                // check if endpoint loaded jobs and continue, else close modal and show 'Please add jobs' msg
                if(this.companyJobs.length != 0){
                    setTimeout(()=>{
                        this.has_mounted = true;
                        this.is_loading = false;
                    }, 500)
                }
                else{
                    if(this.$hasPerm('people') || this.$hasPerm('people-manager')){
                        this.$swal.fire({
                            title: "No Available Jobs",
                            text: "No jobs have been added to this company level, please add at least one job in the Model module.",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#34c38f",
                            cancelButtonColor: "#f46a6a",
                            confirmButtonText: "Go to Job Profile",
                        }).then((result) => {
                            console.log(result);
                            if(result.isConfirmed){
                                if(this.$route.path !== '/Model') {
                                    this.$router.push('/Model')
                                }
                                this.changeProfileTabIndex(8)                               
                            }
                        });
                    }
                    else{
                        this.$swal.fire('No jobs have been added to this company level, please add at least one job in the Model module.')
                    }
                    
                    this.$nextTick(()=>{
                        this.$bvModal.hide('newUserModal');
                    })
                }
                
                
            })
        }
        this.has_mounted = true;
        this.assigned_level_id = this.selected_level.id;
    },
}
</script>

<style scoped>

</style>