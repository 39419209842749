<template>
    <div class="media border-top border-light">
        <div class="media-body" v-if="item.notifiable != null">
            <div class="d-flex">
                <h6 class="mt-0 mb-1">
                    <!--{{item.type}}-->
                    Action Plan
                </h6>
                <span class="ms-auto">
                    <span class="badge bg-info bg-soft text-dark me-1 badge-secondary">{{item.notifiable.type}}</span>
                    <span class="badge bg-primary bg-soft text-dark badge-primary">{{item.status}}</span>
                </span>
            </div>

            <div class="font-size-12 mb-0">
                {{item.notifiable.name}}
            </div>
            <div class="font-size-12 text-muted mb-1">
                From: {{fromName}}
            </div>
            
            <div v-if="item.status == 'Sent'" class="font-size-12 text-muted">
                <p class="mb-1">
                    <b-button size="sm" variant="success" @click="setStatus('accepted')" class="me-1">
                        Accept
                    </b-button>
                    <b-button size="sm" variant="danger" @click="setStatus('rejected')">
                        Reject
                    </b-button>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        item:{
            required: true,
        }
    },
    data:() => ({}),
    computed:{
        fromName(){
            // return owner name
            let name = '';
            if(this.item.notifiable){
                name = this.item.notifiable.owner_user_name;
            }
            console.log(name)
            return name;
        }
    },
    methods:{
        setStatus(type){
            let payload = {
                status: type,
                inbox_id: this.item.id,
            };
            this.$emit('updatedStatus', payload);
        }
    },
}
</script>

<style>

</style>