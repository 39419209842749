<template>
    <div style="height: 100%;" class="" >
        <webix-ui
            v-if="show && selected_level != null"
            v-model="level_data"
            :config="ui">
        </webix-ui>
        
        <b-modal hide-footer title="Reposition Action" size="sm" id="repositionModal" v-if="show_modal">
            <b-overlay :show="is_updating_level">
                <b-alert show variant="info" >
                    Selected Row: 
                    <span class="text-primary text-truncate">{{ repositionSourceName }}</span>
                </b-alert>
                <b-button variant="primary" @click="moveAboveTarget()" size="" class="w-100 mb-2">Move Above <strong>{{ repositionTargetName }}</strong></b-button>
                <b-button variant="primary" @click="moveBelowTarget()" size="" class="w-100">Move Below <strong>{{ repositionTargetName }}</strong></b-button>
                <hr>
                <b-button variant="primary" @click="moveToTargetAsChild()" class="w-100">Move To <strong>{{ repositionTargetName }}</strong> As Child</b-button>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import { levelComputed, levelMethods, webixTable } from '@/state/helpers'
import _ from 'lodash'
import TableHelpers from '@/mixins/tableHelpers.js';
export default {
    name: 'orgChartTree',
    mixins: [TableHelpers],
    props:{
        enable_drag_mode:{
            default: false,
        }
    },
    watch:{
        levels:{
            immediate: true,
            handler(){
                let temp_level_data = _.cloneDeep(this.levels);
                //temp_level_data = _.orderBy(temp_level_data, ['item_order']);
                this.level_data = this.processLevels(temp_level_data);
                
                //this.level_data = [{id: 1, parent: 1, text: 'Saver', data:[]}]
                //this.$nextTick(()=>{
                //    this.restoreHierarchyTree();
                //})
            }
        },
        level_data(){
            this.$nextTick(()=>{
                this.restoreHierarchyTree();
            })

        //    this.$nextTick(()=>{
        //        window.webix.$$('levelsTree').load(this.level_data, 'json', true);
        //    })
        },
        //current_level_id:{
            
            //immediate:true,
            //handler(newValue, oldValue){
            //    if(this.current_level_id != null && window.webix.$$('levelsTree') != undefined){
            //        window.webix.$$('levelsTree').addCss(newValue, 'selected_tree_item')
            //        if(oldValue != undefined){
            //            window.webix.$$('levelsTree').removeCss(oldValue, 'selected_tree_item')
            //        }
            //    }
            //}
        //},
        enable_drag_mode(){
            if(this.enable_drag_mode){
                console.log('Enabled reorder');
                //this.$swal.fire('Select the SOURCE item and then select the TARGET item');
            }
            else{
                this.setRepositionValue({ target: null, source: null })
                this.restoreHierarchyTree();
            }
        },
        'reposition.target'(newVal){
            if(newVal != null){
                this.show_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('repositionModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "repositionModal") {
                            this.show_modal = false;
                            //* reset reposition options
                            this.setRepositionValue({ target: null, source: null })
                        }
                    });
                })
            }
        }
    },
    computed:{
        ...levelComputed,
        ui(){
            let ctx = this;
            return{
                id: 'levelsTree',
                view: 'tree',
                css: 'levelTree',
                drag: false,
                select: true,
                tooltip: true,
                //data: this.level_data,
                template: (obj, common) => {
                    if(ctx.allow_tree_selection){
                        return common.icon(obj, common) + common.checkbox(obj, common) + `<span class="normal-text ms-1 tree_styles">${obj.name}</span>`;
                    }
                    else{
                        if(obj.$count == 0){
                            return `<span class="normal-text tree_styles">
                                <svg xmlns="http://www.w3.org/2000/svg" height="7" width="7" style="fill: rgb(148, 161, 179); font-size: 0.5em; vertical-align: middle; margin-right: 0.7em; margin-bottom: 0.3em; margin-left: 1.1em; padding-right: 0.2em" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"/></svg>
                                ${obj.name}
                                </span>`;
                            //return `<span class="normal-text tree_styles"><i style="color: rgb(148, 161, 179); font-size: 0.5em; vertical-align: middle; margin-right: 1em; margin-bottom: 0.3em; margin-left: 1.1em; padding-right: 0.2em" class="fas fa-circle"></i>${obj.name}</span>`;
                        } else {
                            return common.icon(obj, common) + `<span class="normal-text tree_styles">${obj.name}</span>`;
                        }
                    }
                },
                //template: this.allow_tree_selection ? "{common.icon()}{common.checkbox()} #value#" : "{common.icon()} #value#" ,
                scroll: false,
                //maxHeight: 500,
                filterMode:{
                    showSubItems:false,

                },
                ready(){
                    let el = this.$scope.$parent;
                    el.setLevelTreeLoadedState(true);

                    setTimeout(()=>{
                        el.restoreHierarchyTree();
                        //this.select(el.current_level_id);
                    }, 500)

                    if(window.webix.$$('contextmenu') != undefined){
                        window.webix.$$('contextmenu').destructor();
                    }

                    if(window.webix.$$('contextmenu') == undefined){
                        window.webix.ui({
                            view:"contextmenu",
                            id:'contextmenu',
                            css:'levelContextMenu',
                            master:window.webix.$$("levelsTree"), 
                            data:[],
                            on:{
                                onMenuItemClick:function(id){
                                    let menu = this.getItem(id).value;
                                    el.handleRightClickAction(menu);
                                    this.hide();
                                }
                            }
                        })
                    }
                },
                on:{
                    onItemCheck(id, value, event){
                        //* event will be undefined if setting checkbox value through api
                        if(event != undefined){
                            let el = this.$scope.$parent;
                            if(el.assign_user_levels_mode){
                                //hmm no extra code here
                            }
                            else{
                                //in this mode we have to select the parent nodes to the top of the tree
                                if(value){ //* if true then we must ensure all immediate parents are also enabled
                                    let parent_id = this.getItem(id).$parent;
                                    while(parent_id != 0){
                                        //console.log('WHILE LOG 4')
                                        if(!this.isChecked(parent_id)){
                                            this.checkItem(parent_id);
                                        }
                                        parent_id = this.getItem(parent_id).$parent;
                                    }
                                }
                                else{ //* if false then alll selected children must be deselected
                                    this.data.eachSubItem( id,(children) => {
                                        if(this.isChecked(children.id)){
                                            this.uncheckItem(children.id)
                                        }
                                    })
                                }
                            }
                        }
                    },
                    onBeforeSelect(){
                        let el = this.$scope.$parent;

                        let allow_click = true;
                        if(el.allow_tree_selection){
                            allow_click = false;
                        }
                        if(el.enable_drag_mode){
                            allow_click = false;
                        }
                        return allow_click;
                    },
                    onItemClick(id){
                        let el = this.$scope.$parent;

                        //* check if any visible tables have unsaved changes
                        let has_unsaved_table_changes = el.$allTablesUnsavedChangesCheck();
                        if(has_unsaved_table_changes){
                            return false;
                        }
                        //* continue with level selection if no tables have unsaved changes

                        if(el.allow_tree_selection){
                            return;
                        }
                        else if(el.enable_drag_mode){
                            if(el.reposition.source == null){
                                el.setRepositionValue({ target: null, source: Number(id) })
                            }
                            else{
                                el.setRepositionValue({ target: Number(id), source: el.reposition.source })
                                //el.reposition.target = Number(id);
                            }
                            //reject the click
                            return;
                        }

                        if(el.current_level_id != id){
                            if(el.$hasLevelAccess(id)){
                                el.$emit('clickedLevel', id);
                            }
                        }
                    },
                    //eslint-disable-next-line
                    onBeforeContextMenu: function(id, e, node){
                        let el = this.$scope.$parent;
                        //let item = this.getItem(id);
                        el.right_clicked_id = id;
                        window.webix.$$('contextmenu').clearAll();

                        if(el.$hasPerm('manage-structure')){
                            let options = [];
                            options.push('New Top Level');
                            options.push('Add Child');
                            options.push('Edit Current');
                            options.push('Delete');
    
                            window.webix.$$('contextmenu').define('data', options);
                            window.webix.$$('contextmenu').refresh();
                        }
                    },
                    //onBeforeDrop:function(context){
                    //    context.parent = context.target;	//drop as child
                    //    context.index = -1;
                    //    if(Number(context.parent) != Number(context.start)){
                    //        return true;
                    //    }
                    //    else{
                    //        return false;
                    //    }
                    //},
                    onAfterDrop:function(obj){
                        let id = obj.start; // current item id
                        let parent_id = obj.parent;
                        let el = this.$scope.$parent;
                        el.$emit('onRowDrag', {id: id, parent_id: parent_id});
                    },
                    onBeforeDrop(context){
                        context.parent = context.target;	//drop as child
                        context.index = -1; 	
                    },
                    //onAfterDrop:function(obj){
                    //    console.log('Dragged obj', obj);
                    //},
                    onAfterOpen(){
                        let el = this.$scope.$parent;
                        if(el.block_saving_tree_state == false){
                            el.saveLevelsTreeState(this.getState());
                        }
                    },
                    onAfterClose(){
                        let el = this.$scope.$parent;
                        if(el.block_saving_tree_state == false){
                            el.saveLevelsTreeState(this.getState());
                        }
                    },
                    
                    //eslint-disable-next-line
                    //onBeforeDrop:function(ctx, ev){
                    //    // aceppt only dnd on data area
                    //    if(!ctx.target || !this.getItem(ctx.target)) return false; 
                    //    if (this.getItem(ctx.target).$count && this.getItem(ctx.target).open){
                    //    // drop as first child
                    //    ctx.parent = ctx.target;
                    //    ctx.index = 0;
                    //    } else {
                    //    // drop next
                    //    ctx.index++;
                    //    }
                    //}
                },
            }
        },
        repositionTargetName(){
            let name = '';
            if(!this.reposition.target) {
                return;
            }
            else{
                //* find level name for the target from levels vuex
                let level = _.find( this.levels, { id: this.reposition.target}) 
                if(level){
                    name = level.name;
                }
                return name;
            }
        },
        repositionSourceName(){
            let name = '';
            if(!this.reposition.source) {
                return;
            }
            else{
                //* find level name for the source from levels vuex
                let level = _.find( this.levels, { id: this.reposition.source}) 
                if(level){
                    name = level.name;
                }
                return name;
            }
        }
    },
    data: () => ({
        block_saving_tree_state: false,
        is_updating_level: false,

        show: false,
        level_data:[],
        context_menu_id: null,
        right_clicked_id: null,

        show_modal: false,
        //reposition:{
        //    target: null,
        //    source: null,
        //}
    }),
    methods:{
        ...levelMethods,
        ...webixTable,
        toggle(){
            this.show = !this.show;
        },
        handleRightClickAction(menu){
            this.$emit('levelContextAction', {
                id: this.right_clicked_id, menu_action: menu
            });
        },
        restoreTreeState(){
            let tree = window.webix.$$('levelsTree');
            this.$nextTick(()=>{
                if(this.levels_tree_state && tree){
                    this.block_saving_tree_state = true;
                    this.levels_tree_state.open.forEach( id => {
                        tree.open(id);
                    })
                    if(tree.exists(this.current_level_id)){
                        tree.select(this.current_level_id);
                    }
                    this.block_saving_tree_state = false;
                    //this.levels_tree_state.select.forEach( id => {
                    //    tree.select(id);
                    //})
                }
            })
        },
        restoreHierarchyTree(){
            if(this.current_level_id != null){
                let id_array = [];
                let item = _.find(this.levels, {id: this.current_level_id});
                id_array.push(this.current_level_id);
                if(item != undefined){
                    let tries = 0;
                    while(item.parent_id != null && tries < 10){
                        //console.log('WHILE LOG 6 (tries)', tries)
                        id_array.push(item.parent_id);
                        //if(item.parent_id != null){
                        let object = _.find(this.levels, {id: item.parent_id});
                        if(object != undefined){
                            item = object;
                        }
                        else{
                            item.parent_id = null;
                            //tries++;
                        }
                        //}
                    }
                    id_array.reverse();
                    this.$nextTick(()=>{
                        //id_array.forEach( id => {
                        //    window.webix.$$('levelsTree').open(id);
                        //})
                        this.restoreTreeState();
                    })
                }
            }
        },
        processLevels(levels){
            this.show = false;
            var flat_array = levels != null ? _.cloneDeep(levels) : [];
            const map = {};
            const nestedArray = [];
            let pre_processed_array = [];
            if(flat_array.length == 0){
                this.show = true;
                return [];
            }
            pre_processed_array = _.uniqBy(flat_array, 'id');
            pre_processed_array = pre_processed_array.map((item) => {
                let new_item = item;
                // give element the required fields
                new_item["parent"] = new_item.parent_id; //used for webix related stuff
                new_item["value"] = new_item.name; //used for webix related stuff
                new_item['data'] = []; // array of children for the node
                map[item.id] = { ...new_item, };
                return new_item;
            });
            pre_processed_array.forEach((item) => {
                if (item.parent_id !== null) {
                    if (map[item.parent_id]) {
                        map[item.parent_id].data.push(map[item.id]);
                    }
                    else {
                        nestedArray.push(map[item.id]);
                    }
                }
                else {
                    nestedArray.push(map[item.id]);
                }
            });
            this.show = true;
            return nestedArray;
        },
        processLevelsOld(levels) {
            this.show = false;
            var data = _.cloneDeep(levels);
            if(data == null){
                data = [];
            }
            else{
                data.forEach((element) => {
                    // give element the required fields
                    element["parent"] = element.parent_id; //used for webix related stuff
                    element["value"] = element.name; //used for webix related stuff
                    element['data'] = []; // array of children for the node
                });

                data.forEach((ele) => {
                    let parent_id = ele.parent_id;
                    if (parent_id == null) {
                    //* Parent item -- do nothing
                    //TODO - remove old empty block and replace statement with parent_id != null
                    }
                    else {
                    //* If element is a child element, push to children array.
                        data.forEach((d) => {
                            if (d.id === parent_id) {
                                let childArray = d.data;
                                if (!childArray) {
                                    childArray = [];
                                }
                                childArray.push(ele);
                                d.data = childArray;
                            }
                        });
                    }
                });
                //* Remove the leftover / duplicate elements
                data = data.filter((ele) => ele.parent_id != ele.id);
                data = data.filter((ele) => ele.parent_id == null);
                //*Set parent root as expanded;
                /*if(data[0]!= undefined){
                    data[0]["opened"] = true;
                }*/
            }

            this.show = true;
            //setTimeout(()=>{
                //* fix issue of trying where the same view is being created with the same id
                //* only create the context menu if the context menu component is undefined
                
            //}, 500)
            return data;
            },
        resizeTable(){
            if(window.webix.$$('levelsTree') != undefined){
                window.webix.$$('levelsTree').refresh();
            }
        },
        saveUpdatedRowOrder(rows){
            let promises = [];
            rows.forEach( (row, index) => {
                let params = {
                    "parent_id": row.parent_id,
                    "id": row.id,
                    "item_order": index + 1,
                    "name": row.name,
                }
                promises.push(this.updateLevel(params));
            })
            Promise.all(promises).then(()=>{
                this.loadLevels()
                .then(()=>{
                    this.is_updating_level = false;
                    this.show_modal = false;
                    this.setRepositionValue({ target: null, source: null,})
                })
            })
            .catch(()=>{
                this.is_updating_level = false;
            })
        },
        updateArray(array, itemId, newOrderIndex) {
            console.log(itemId, newOrderIndex);
            const itemIndex = array.findIndex(item => item.id == itemId);
            if (itemIndex === -1) {
                return array;
            }
            // Get the item being moved
            const movedItem = array[itemIndex];
            console.log('Moved Item', movedItem)
            // Remove the item from the array
            array.splice(itemIndex, 1);
            // Find the index of the item below which the moved item should be placed
            const newIndex = array.findIndex(item => item.item_order > newOrderIndex);
            // Insert the moved item at the new index
            if (newIndex === -1) {
                // If no item found with higher order_index, push the moved item to the end
                array.push(movedItem);
            } else {
                // Insert the moved item at the new index
                array.splice(newIndex, 0, movedItem);
            }
            //array.forEach( (item, index) => {
            //    item.order_index = index + 1;
            //})
            return array;
        },

        //reorder endpoints
        moveToTargetAsChild(){
            let target = _.find(this.levels, { id: this.reposition.target});
            //let source = _.find(this.levels, { id: this.reposition.source});
            let source = window.webix.$$('levelsTree').getItem(this.reposition.source);
            source.parent_id = target.id;
            ////* --------------------- //
            let rows = [];
            window.webix.$$('levelsTree').data.eachChild(target.id,function(obj){
                rows.push(obj);
            });
            rows.unshift(source);
            this.is_updating_level = true;
            this.saveUpdatedRowOrder(rows);
        },
        moveBelowTarget(){
            let rows = [];
            let source = window.webix.$$('levelsTree').getItem(this.reposition.source);
            window.webix.$$('levelsTree').data.eachChild(source.parent_id,function(obj){
                let item_index = window.webix.$$('levelsTree').getBranchIndex(obj.id);
                obj.item_order = item_index + 1;
                rows.push(obj);
                window.webix.$$('levelsTree').updateItem(obj.id, obj);
            });
            //* get items (should have correct index order now)
            let target = window.webix.$$('levelsTree').getItem(this.reposition.target);
            source = window.webix.$$('levelsTree').getItem(this.reposition.source);
            rows = this.updateArray(rows, source.id, target.item_order);
            this.is_updating_level = true;
            this.saveUpdatedRowOrder(rows)
            
        },
        moveAboveTarget(){
            let rows = [];
            let source = window.webix.$$('levelsTree').getItem(this.reposition.source);
            window.webix.$$('levelsTree').data.eachChild(source.parent_id,function(obj){
                let item_index = window.webix.$$('levelsTree').getBranchIndex(obj.id);
                obj.item_order = item_index + 1;
                rows.push(obj);
                window.webix.$$('levelsTree').updateItem(obj.id, obj);
            });
            //* get items (should have correct index order now)
            let target = window.webix.$$('levelsTree').getItem(this.reposition.target);
            source = window.webix.$$('levelsTree').getItem(this.reposition.source);
            rows = this.updateArray(rows, source.id, target.item_order - 1);
            this.is_updating_level = true;
            this.saveUpdatedRowOrder(rows)
        },
        updateLevel(params){
            return new Promise( (resolve, reject) => {
                window.axios.post("api/admin/savelevel", params)
                .then(()=>{
                    resolve();
                })
                .catch(()=>{
                    reject();
                })
            })
            
        }
    },
    beforeDestroy(){
        this.show = false;
        //window.el = undefined;
        this.$eventHub.$off('windowResizedEvent');
        window.webix.$$('contextmenu').destructor();
        window.webix.$$('levelsTree').destructor();
    },
    mounted(){
        this.$eventHub.$on('windowResizedEvent', this.resizeTable);
        //window.el = this;
        //this.context_menu_id = window.webix.uid();
        this.setLevelTreeLoadedState(false);
        if(this.enable_drag_mode){
            window.webix.$$('levelsTree').define({'drag': this.enable_drag_mode});
        }
    }
}
</script>

<style>
.levelTree{
    height: unset !important;
    min-height: 200px !important;
}
.levelTree .webix_tree_item{
    height: 100%;
    white-space: nowrap;
    overflow: unset !important;
}
.webix_view.webix_tree{
    border: 0;
    width: unset !important;
}

.tree_styles {
    width: 100%;
    word-wrap: normal;
    word-break: break-word;
    white-space: normal;
    display:inline-block;
    height: auto;
    /* text-align: unset;  */
    /* text-indent: -1.3%; */
    /* padding-left: 2em !important; */
    /* margin-left: 0 auto */
}


.webix_view.webix_window.webix_popup.webix_menu.levelContextMenu{
    z-index: 9999 !important;
}
</style>