<template>
    <b-dropdown
    class="datefilterDropdown cursor-pointer"
        menu-class="p-0"
        toggle-class="header-item noti-icon pb-0"
        variant="black"
        ref="datepickerFilterDropdown"
        no-caret>

        <template v-slot:button-content>
                <div>
                    <b-button size="sm" variant="light" class="w-100 pt-0 pb-0">
                        {{ getQuarterText }}
                    </b-button>
                    <div style="height: 0.1rem;"></div>
                    <b-button size="sm" variant="light" class="w-100 pt-0 pb-0">
                        {{ getYearText }}
                    </b-button>
                </div>
        </template>

        <b-card :disabled="objective_viewing_quarter == 0" class=" p-0 m-0 small-text border border-primary border-2">
            <div class="w-100 btn-group">
                <b-button @click="setViewingQuarter(0)" :variant="objective_viewing_quarter == 0 ? 'primary':'outline-dark'" block  size="sm">
                    {{ getButtonYearText }}
                </b-button>
            </div>
            <hr class="mt-1 mb-1">
            <label for="" class="p-0 m-0 small-text">
                Quarter
            </label>
            <div class="w-100 btn-group">
                <!-- <b-button @click="setViewingQuarter(0)" :variant="objective_viewing_quarter == 0 ? 'primary':'outline-dark'" block  size="sm">All</b-button> -->
                <b-button :disabled="!this.selected_milestone" @click="setViewingQuarter(1)" :variant="objective_viewing_quarter == 1 ? 'primary':'outline-dark'" block  size="sm">Q1</b-button>
                <b-button :disabled="!this.selected_milestone" @click="setViewingQuarter(2)" :variant="objective_viewing_quarter == 2 ? 'primary':'outline-dark'" block  size="sm">Q2</b-button>
                <b-button :disabled="!this.selected_milestone" @click="setViewingQuarter(3)" :variant="objective_viewing_quarter == 3 ? 'primary':'outline-dark'" block  size="sm">Q3</b-button>
                <b-button :disabled="!this.selected_milestone" @click="setViewingQuarter(4)" :variant="objective_viewing_quarter == 4 ? 'primary':'outline-dark'" block  size="sm">Q4</b-button>
            </div>
            <hr class="mt-1 mb-1">
            <label for="" class="p-0 m-0 small-text">
                Half
            </label>
            <div class="w-100 btn-group">
                <b-button :disabled="!this.selected_milestone" @click="setViewingQuarter(5)" :variant="objective_viewing_quarter == 5 ? 'primary':'outline-dark'" block size="sm" >H1</b-button>
                <b-button :disabled="!this.selected_milestone" @click="setViewingQuarter(6)" :variant="objective_viewing_quarter == 6 ? 'primary':'outline-dark'" block size="sm" >H2</b-button>
            </div>

            <!-- <hr class="mt-1 mb-1">
            <label for="" class="p-0 m-0 small-text">
                Range
            </label>

            <div class="d-flex">
                <span class="ms-auto">

                
                    <kbd class="customKbd"> Start </kbd>
                    &nbsp;
                    <span style="font-size:0.6rem;">to</span>
                    &nbsp;
                    <kbd class="customKbd"> End </kbd>
                </span>
            </div> -->
            
        </b-card>

    </b-dropdown>
</template>

<script>
import { activityComputed, activityMethods, levelComputed } from '@/state/helpers'
export default {
    data:() => ({}),
    computed:{
        ...levelComputed,
        ...activityComputed,
        getQuarterText(){
            if(this.objective_viewing_quarter == 0){
                if(!this.selected_milestone){
                    return 'Full Data Range'
                }
                else{
                    return 'Full Year'
                }
            }
            else if(this.objective_viewing_quarter <= 4){
                return `Quarter ${this.objective_viewing_quarter}`;
            }
            else if(this.objective_viewing_quarter == 5){
                return `H1` 
            }
            else{
                return `H2` 
            }
        },
        getYearNumber(){
            if(this.selected_milestone){
                return this.selected_milestone.year
            }
            else{
                return ''
            }
        },
        getYearText(){
            if(this.selected_milestone){
                return this.selected_milestone.year
            }
            else{
                return 'For all years'
            }
        },
        getButtonYearText(){
            if(this.selected_milestone){
                return this.selected_milestone.year
            }
            else{
                return 'For all years'
            }
        }
    },
    methods:{
        ...activityMethods,
        setViewingQuarter(quarter){
            //this.$plausible.trackEvent('Changed Viewing Period');
            this.setObjectiveQuarter(quarter)
            this.$refs.datepickerFilterDropdown.hide();
            this.filterObjectivesByQuarter();
        }
    },
    mounted(){},
}
</script>

<style>
.datefilterDropdown{
    margin-top: -8px;
}
.datefilterDropdown>ul{
    margin-top: -12px;
}
</style>