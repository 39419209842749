var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.is_loading}},[_c('div',{staticStyle:{"padding-top":"0.1rem"},attrs:{"id":"sidebar-menu-old","keep-alive":""}},[(_vm.is_collapsed == false)?_c('b-row',[(false)?_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.printRouteName))])]):_vm._e(),_c('b-col',{staticClass:"pt-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"btn-group",staticStyle:{"display":"flex","flex-basis":"100%","justify-content":"center","flex-direction":"column","align-items":"center"}},[_c('b-card',{staticClass:"set-grey-border",staticStyle:{"width":"88%"}},[_c('div',{staticStyle:{"display":"flex","flex-basis":"100%","justify-content":"center","flex-direction":"column","align-items":"center"}},[_c('h6',{staticStyle:{"margin-bottom":"4px","font-size":"0.75rem"}},[_vm._v(" Modules ")])]),_c('div',{staticStyle:{"display":"flex","flex-basis":"100%","justify-content":"center","align-items":"center"}},[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"exact":"","to":"/model"}},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:({
                      customClass: 'my-popover-class',
                      placement: 'bottomleft',
                      content: 'Model'
                    }),expression:"{\n                      customClass: 'my-popover-class',\n                      placement: 'bottomleft',\n                      content: 'Model'\n                    }",modifiers:{"hover":true}}],staticClass:"quadButton",staticStyle:{"width":"35px"},attrs:{"size":"sm","variant":_vm.isActiveRoute('model'),"data-toggle":"tooltip","squared":""},on:{"click":function($event){return _vm.setViewType('model')}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-fw fa-bar-chart"}})],1)],1),(_vm.$hasPerm('people') || _vm.$hasPerm('people-manager'))?_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"exact":"","to":"/people"}},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:({
                      customClass: 'my-popover-class',
                      placement: 'bottomleft',
                      content: 'People'
                    }),expression:"{\n                      customClass: 'my-popover-class',\n                      placement: 'bottomleft',\n                      content: 'People'\n                    }",modifiers:{"hover":true}}],staticClass:"quadButton",staticStyle:{"width":"35px"},attrs:{"size":"sm","variant":_vm.isActiveRoute('people'),"squared":""},on:{"click":function($event){return _vm.setViewType('people')}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-users"}})],1)],1):_vm._e(),(
                    _vm.$hasPerm('performance') || _vm.$hasPerm('performance-manager')
                  )?_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"exact":"","to":"/performance"}},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:({
                      customClass: 'my-popover-class cursor-pointer',
                      placement: 'bottomleft',
                      content: 'Performance'
                    }),expression:"{\n                      customClass: 'my-popover-class cursor-pointer',\n                      placement: 'bottomleft',\n                      content: 'Performance'\n                    }",modifiers:{"hover":true}}],staticClass:"quadButton",staticStyle:{"width":"35px"},attrs:{"size":"sm","variant":_vm.isActiveRoute('performance'),"squared":""},on:{"click":function($event){return _vm.setViewType('performance')}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-fw fa-chart-line"}})],1)],1):_vm._e(),(_vm.$hasPerm('process') || _vm.$hasPerm('process-manager'))?_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"exact":"","to":"/handbook"}},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:({
                      customClass: 'my-popover-class',
                      placement: 'bottomleft',
                      content: 'Handbook'
                    }),expression:"{\n                      customClass: 'my-popover-class',\n                      placement: 'bottomleft',\n                      content: 'Handbook'\n                    }",modifiers:{"hover":true}}],staticClass:"quadButton",staticStyle:{"width":"35px"},attrs:{"size":"sm","variant":_vm.isActiveRoute('handbook'),"squared":""},on:{"click":function($event){return _vm.setViewType('handbook')}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-fw fa-book"}})],1)],1):_vm._e(),(_vm.isDevEnv())?_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"exact":"","to":"/meetings"}},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:({
                      customClass: 'my-popover-class',
                      placement: 'bottomleft',
                      content: 'Meetings'
                    }),expression:"{\n                      customClass: 'my-popover-class',\n                      placement: 'bottomleft',\n                      content: 'Meetings'\n                    }",modifiers:{"hover":true}}],staticClass:"quadButton",staticStyle:{"width":"35px"},attrs:{"size":"sm","variant":_vm.isActiveRoute('meetings'),"squared":""},on:{"click":function($event){return _vm.setViewType('meetings')}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-fw fa-file-alt"}})],1)],1):_vm._e(),(_vm.isDevEnv())?_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"exact":"","to":"/scheduler"}},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:({
                      customClass: 'my-popover-class',
                      placement: 'bottomleft',
                      content: 'Scheduler'
                    }),expression:"{\n                      customClass: 'my-popover-class',\n                      placement: 'bottomleft',\n                      content: 'Scheduler'\n                    }",modifiers:{"hover":true}}],staticClass:"quadButton",staticStyle:{"width":"35px"},attrs:{"size":"sm","variant":_vm.isActiveRoute('scheduler'),"squared":""},on:{"click":function($event){return _vm.setViewType('scheduler')}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-fw fa-clock"}})],1)],1):_vm._e()],1)])],1)])],1):_c('b-row',[_c('b-col',{staticClass:"text-center mb-2",staticStyle:{"margin":"0 auto"},attrs:{"cols":"12"}},[_c('router-link',{staticClass:"border border-red side-nav-link-ref",attrs:{"exact":"","to":"/model"}},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:({
                customClass: 'my-popover-class',
                placement: 'bottom',
                content: 'Model'
              }),expression:"{\n                customClass: 'my-popover-class',\n                placement: 'bottom',\n                content: 'Model'\n              }",modifiers:{"hover":true}}],staticClass:"quadButton",staticStyle:{"width":"44px","height":"35px","margin-top":"5px","padding":"0 auto"},attrs:{"variant":_vm.isActiveRoute('Model'),"squared":""},on:{"click":function($event){return _vm.setViewType('model')}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-bar-chart"}})],1)],1)],1),_c('b-col',{staticClass:"text-center mb-2",attrs:{"cols":"12"}},[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"exact":"","to":"/people"}},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:({
                customClass: 'my-popover-class',
                placement: 'auto',
                content: 'People'
              }),expression:"{\n                customClass: 'my-popover-class',\n                placement: 'auto',\n                content: 'People'\n              }",modifiers:{"hover":true}}],staticClass:"quadButton",staticStyle:{"width":"44px","height":"35px"},attrs:{"variant":_vm.isActiveRoute('people'),"squared":""},on:{"click":function($event){return _vm.setViewType('people')}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-users"}})],1)],1)],1),_c('b-col',{staticClass:"text-center mb-2",attrs:{"cols":"12"}},[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"exact":"","to":"/performance"}},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:({
                customClass: 'my-popover-class',
                placement: 'auto',
                content: 'Performance'
              }),expression:"{\n                customClass: 'my-popover-class',\n                placement: 'auto',\n                content: 'Performance'\n              }",modifiers:{"hover":true}}],staticClass:"quadButton",staticStyle:{"width":"44px","height":"35px"},attrs:{"variant":_vm.isActiveRoute('objectives'),"squared":""},on:{"click":function($event){return _vm.setViewType('activity')}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-chart-line"}})],1)],1)],1),_c('b-col',{staticClass:"text-center mb-2",attrs:{"cols":"12"}},[_c('router-link',{staticClass:"side-nav-link-ref",attrs:{"exact":"","to":"/handbook"}},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:({
                customClass: 'my-popover-class',
                placement: 'bottom',
                content: 'Handbook'
              }),expression:"{\n                customClass: 'my-popover-class',\n                placement: 'bottom',\n                content: 'Handbook'\n              }",modifiers:{"hover":true}}],staticClass:"quadButton",staticStyle:{"width":"44px","height":"35px"},attrs:{"variant":_vm.isActiveRoute('handbook'),"squared":""},on:{"click":function($event){return _vm.setViewType('handbook')}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-handshake"}})],1)],1)],1),(_vm.isDevEnv())?_c('b-col',{staticClass:"text-center mb-2",attrs:{"cols":"12"}},[_c('router-link',{staticClass:"border border-red side-nav-link-ref",attrs:{"exact":"","to":"/meetings"}},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:({
                customClass: 'my-popover-class',
                placement: 'bottom',
                content: 'Meetings'
              }),expression:"{\n                customClass: 'my-popover-class',\n                placement: 'bottom',\n                content: 'Meetings'\n              }",modifiers:{"hover":true}}],staticClass:"quadButton",staticStyle:{"width":"44px","height":"35px"},attrs:{"variant":_vm.isActiveRoute('meetings'),"squared":""},on:{"click":function($event){return _vm.setViewType('meetings')}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-file-alt"}})],1)],1)],1):_vm._e()],1),(_vm.is_collapsed == false)?_c('div',[(_vm.show_menu)?_c('LevelMenu'):_vm._e(),_c('SearchModal')],1):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }