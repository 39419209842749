<template>
    <div>
        <BaseCard>
            <template #header>
                Document
            </template>
            <template #title>
                {{ itemName }}
            </template>
            <template #body>
                <b-button @click="openDocument" size="sm" variant="outline-light" class="me-1">
                    View
                </b-button>
                <b-button v-if="is_unread_notification" size="sm" variant="outline-light" @click="markAsRead">
                    Accept
                </b-button>
                <!-- <pre>{{ item }}</pre> -->
            </template>
        </BaseCard>
        <b-overlay no-wrap :show="is_loading"></b-overlay>
    </div>
</template>

<script>
import { adminMethods } from '@/state/helpers';
import BaseCard from './base-card.vue';
export default {
    components:{
        BaseCard,
    },
    props:{
        item:{
            type: Object,
            required: true,
        },
        is_unread_notification:{
            type: Boolean,
            required: false,
        }
    },
    data:() => ({
        is_loading: false,
    }),
    computed:{
        itemName(){
            if(this.item.data.type == 'document_assignment') {
               return this.item.data.document_title
            }
            return this.item.data.key_action_name;
        }
    },
    methods:{
        ...adminMethods,
        viewDoc(){
            //* push route to process/{document_id}
            if(this.is_unread_notification){
                this.markNotificationAsRead({id: this.item.id})
            }
            this.$router.push(`/handbook/${this.item.data.document_id}`);
        },
        markAsRead(){
            this.is_loading = true;
            this.markNotificationAsRead({id: this.item.id})
            .then(()=>{
                this.loadNotifications()
                .then(()=>{
                    this.is_loading = false;
                })
            })
            .catch(()=>{
                this.is_loading = false;
            })
        },
        openDocument() {
            this.$router.push(`/handbook/${this.item.data.token}`);
        }
    },
    mounted(){},
}
</script>

<style>

</style>