<script>
//import MetisMenu from "metismenujs/dist/metismenujs";
import LevelMenu from "./menu/level-menu.vue";
//import NavigationMenu from './menu/navigation-menu.vue';
import _ from "lodash";
import {
  settingsComputed,
  adminMethods,
  adminComputed,
  levelMethods,
  levelComputed,
  menuMethods,
  menuState
} from "@/state/helpers";
//import router from "@/router";
import SearchModal from "@/components/widgets/searchModal";
import { menuItems } from "./menu";

/**
 * Sidenav component
 */
export default {
  data: () => ({
    menuItems: menuItems,
    menuData: null,
    show_menu: false
    //is_loading: false,
  }),
  components: {
    //NavigationMenu,
    LevelMenu,
    SearchModal
  },
  mounted: function() {
    if (this.levels != null) {
      this.show_menu = true;
    }
  },
  computed: {
    ...levelComputed,
    ...adminComputed,
    ...settingsComputed,
    ...menuState,
    is_collapsed() {
      return this.is_left_menu_collapsed;
    },
    is_loading() {
      if (this.menu_loading === "true") {
        return true;
      }
      if (this.menu_loading === "false") {
        return false;
      }

      return this.menu_loading;
    },
    printRouteName() {
      switch (this.$route.name) {
        case "objectives": {
          return "Performance";
        }
        case "people": {
          return "People";
        }
        case "process": {
          return "Process";
        }
        case "model": {
          return "Org Profile";
        }
        case "profile": {
          return "Org Profile";
        }
        default: {
          return "";
        }
      }
    }
  },
  methods: {
    ...menuMethods,
    ...adminMethods,
    ...levelMethods,
    setLoading: menuMethods.setLoading,
    initLevelSelection() {
      setTimeout(() => {
        if (this.levels == null) {
          this.loadLevels().then(() => {
            this.setCurrentLevel();
          });
        } else {
          this.setCurrentLevel();
        }
      }, 200);
    },
    setCurrentLevel() {
      if (this.core_loaded == false) {
        return;
      }
      else if (this.selected_level == null) {
        let index = -1;
        if (this.core.admin) {
          index = _.findIndex(this.levels, { parent_id: 0 });
        } else if (
          this.core.user_levels != null ||
          this.core.user_levels != []
        ) {
          index = _.findIndex(this.levels, { id: this.core.user_levels[0].id });
        }

        if (index != -1) {
          this.selectLevel(this.levels[index]).then(() => {
            this.$eventHub.$emit("newLevelSelected");
          });
        }
        this.$nextTick(() => {
          this.show_menu = true;
        });
      } 
      else {
        this.$nextTick(() => {
          this.show_menu = true;
        });
      }
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },

    // toggleMenu(event) {
    //   //this.is_collapsed = !this.is_collapsed;
    //   event.currentTarget.nextElementSibling.classList.toggle("mm-show");
    // },
    isActiveRoute(name) {
      //* get the base route param fron the route path
      let route_param = this.$route.path.split("/");
      if (route_param[1] == undefined) {
        return "light";
      } else if (route_param[1] == name) {
        return "success";
      } else {
        return "light";
      }
      /*if(this.$route.name == name){
                return 'success'
            }
            else if(this.$route.name == 'person' && name == 'people'){
                return 'success'
            }
            else{
                return 'light'
            }*/
    },
    setViewType(type) {
      let current_view = window.sessionStorage.getItem("current_view");
      if (current_view === type) {
        setTimeout(() => {
          this.setLoading(false);
        }, 600);
      }
      if (current_view !== type) {
        this.setLoading(true);
        window.sessionStorage.setItem("current_view", type);
      }
    },
    //setLoading(){
    //    this.is_loading = true;
    //},
    //clearLoading(){
    //    this.is_loading = false;
    //    //clear b-overlay
    //},
    handleMenuToggle() {
      this.is_collapsed = !this.is_collapsed;
    }
  }
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div>
    <!--- Sidemenu -->
    <b-overlay :show="is_loading">
      <div id="sidebar-menu-old" keep-alive style="padding-top: 0.1rem;">
        <!-- Search Bar -->
        <!--<b-row v-if="is_collapsed == false">
            <b-col cols="8">
                <b-form-input placeholder="Search..." style="margin-left: 1rem;" type="text"></b-form-input>
            </b-col>
            <b-col cols="4">
                <b-button @click="$bvModal.show('searchModal')" style="float:right; margin-right: 1rem;">
                    <font-awesome-icon icon="fa-solid fa-search" />
                </b-button>
            </b-col>
        </b-row>-->
        <b-row v-if="is_collapsed == false">
          <b-col v-if="false" cols="12" class="pb-1">
            <div style="text-align: center;">{{ printRouteName }}</div>
          </b-col>
          <b-col cols="12" class="pt-2">
            <div
              class="btn-group"
              style="display:flex; flex-basis: 100% ;justify-content: center; flex-direction: column; align-items: center;"
            >
              <b-card class="set-grey-border" style="width: 88%">
                <div
                  style="display:flex; flex-basis: 100% ;justify-content: center; flex-direction: column; align-items: center;"
                >
                  <h6 style="margin-bottom: 4px; font-size: 0.75rem;">
                    Modules
                  </h6>
                </div>
                <div
                  style="display:flex; flex-basis: 100% ;justify-content: center; align-items: center;"
                >
                  <router-link exact to="/model" class="side-nav-link-ref">
                    <b-button
                      size="sm"
                      v-b-popover.hover="{
                        customClass: 'my-popover-class',
                        placement: 'bottomleft',
                        content: 'Model'
                      }"
                      style="width: 35px;"
                      @click="setViewType('model')"
                      class="quadButton"
                      :variant="isActiveRoute('model')"
                      data-toggle="tooltip"
                      squared
                    >
                      <font-awesome-icon icon="fa-fw fa-bar-chart" />
                    </b-button>
                  </router-link>
                  <router-link
                    v-if="$hasPerm('people') || $hasPerm('people-manager')"
                    exact
                    to="/people"
                    class="side-nav-link-ref"
                  >
                    <b-button
                      v-b-popover.hover="{
                        customClass: 'my-popover-class',
                        placement: 'bottomleft',
                        content: 'People'
                      }"
                      size="sm"
                      style="width: 35px;"
                      @click="setViewType('people')"
                      class="quadButton"
                      :variant="isActiveRoute('people')"
                      squared
                    >
                      <font-awesome-icon icon="fa-users" />
                    </b-button>
                  </router-link>
                  <router-link
                    v-if="
                      $hasPerm('performance') || $hasPerm('performance-manager')
                    "
                    exact
                    to="/performance"
                    class="side-nav-link-ref"
                  >
                    <b-button
                      v-b-popover.hover="{
                        customClass: 'my-popover-class cursor-pointer',
                        placement: 'bottomleft',
                        content: 'Performance'
                      }"
                      size="sm"
                      style="width: 35px;"
                      @click="setViewType('performance')"
                      class="quadButton"
                      :variant="isActiveRoute('performance')"
                      squared
                    >
                      <font-awesome-icon icon="fa-fw fa-chart-line" />
                    </b-button>
                  </router-link>

                  <!-- <router-link
                    v-if="false"
                    exact
                    to="/chat"
                    class="side-nav-link-ref"
                  >
                    <b-button
                      v-b-popover.hover="{
                        customClass: 'my-popover-class cursor-pointer',
                        placement: 'bottomleft',
                        content: 'Chat'
                      }"
                      size="sm"
                      style="width: 35px;"
                      @click="setViewType('chat')"
                      class="quadButton"
                      :variant="isActiveRoute('chat')"
                      squared
                    >
                      <font-awesome-icon icon="fa-fw fa-comments" />
                    </b-button>
                  </router-link> -->
                  <router-link
                    v-if="$hasPerm('process') || $hasPerm('process-manager')"
                    exact
                    to="/handbook"
                    class="side-nav-link-ref"
                  >
                    <b-button
                      v-b-popover.hover="{
                        customClass: 'my-popover-class',
                        placement: 'bottomleft',
                        content: 'Handbook'
                      }"
                      size="sm"
                      style="width: 35px;"
                      @click="setViewType('handbook')"
                      class="quadButton"
                      :variant="isActiveRoute('handbook')"
                      squared
                    >
                      <font-awesome-icon icon="fa-fw fa-book" />
                    </b-button>
                  </router-link>
                  <router-link
                    v-if="isDevEnv()"
                    exact
                    to="/meetings"
                    class="side-nav-link-ref"
                  >
                    <b-button
                      v-b-popover.hover="{
                        customClass: 'my-popover-class',
                        placement: 'bottomleft',
                        content: 'Meetings'
                      }"
                      size="sm"
                      style="width: 35px;"
                      @click="setViewType('meetings')"
                      class="quadButton"
                      :variant="isActiveRoute('meetings')"
                      squared
                    >
                      <font-awesome-icon icon="fa-fw fa-file-alt" />
                    </b-button>
                  </router-link>
                  <!--<router-link v-if="isDevEnv()" to="/performance/" class="side-nav-link-ref">
                            <b-button @click="setViewType('performance'); setLoading(true);" class="quadButton" :variant="isActiveRoute('performance')" style="width: 50%;" squared>Performance</b-button>
                        </router-link>-->
                  <router-link
                    v-if="isDevEnv()"
                    exact
                    to="/scheduler"
                    class="side-nav-link-ref"
                  >
                    <b-button
                      size="sm"
                      v-b-popover.hover="{
                        customClass: 'my-popover-class',
                        placement: 'bottomleft',
                        content: 'Scheduler'
                      }"
                      style="width: 35px;"
                      @click="setViewType('scheduler')"
                      class="quadButton"
                      :variant="isActiveRoute('scheduler')"
                      squared
                    >
                      <font-awesome-icon icon="fa-fw fa-clock" />
                    </b-button>
                  </router-link>

                  <!-- to enable, remove :is="'span'" -->
                  <!-- //! temp removal
                        <router-link :is="'span'" exact to="/process" class="side-nav-link-ref">
                            <b-button disabled @click="setViewType('process'); setLoading(true);" class="quadButton" :variant="isActiveRoute('process')" style="width: 50%;" squared>Process</b-button>
                        </router-link>
                        <router-link :is="'span'" exact to="/approvals" class="side-nav-link-ref">
                            <b-button disabled @click="setViewType('approvals'); setLoading(true);" class="quadButton" :variant="isActiveRoute('approvals')" style="width: 50%;" squared>Approvals</b-button>
                        </router-link>
                        -->
                </div>
              </b-card>
            </div>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col cols="12" style="margin:0 auto" class="text-center mb-2">
            <router-link
              exact
              to="/model"
              class="border border-red side-nav-link-ref"
            >
              <b-button
                v-b-popover.hover="{
                  customClass: 'my-popover-class',
                  placement: 'bottom',
                  content: 'Model'
                }"
                style="width: 44px;height: 35px; margin-top: 5px; padding: 0 auto"
                @click="setViewType('model')"
                class="quadButton"
                :variant="isActiveRoute('Model')"
                squared
              >
                <font-awesome-icon icon="fa-bar-chart" />
              </b-button>
            </router-link>
          </b-col>
          <b-col cols="12" class="text-center mb-2">
            <router-link exact to="/people" class="side-nav-link-ref">
              <b-button
                v-b-popover.hover="{
                  customClass: 'my-popover-class',
                  placement: 'auto',
                  content: 'People'
                }"
                style="width: 44px;height: 35px;"
                @click="setViewType('people')"
                class="quadButton"
                :variant="isActiveRoute('people')"
                squared
              >
                <font-awesome-icon icon="fa-users" />
              </b-button>
            </router-link>
          </b-col>
          <b-col cols="12" class="text-center mb-2">
            <router-link exact to="/performance" class="side-nav-link-ref">
              <b-button
                v-b-popover.hover="{
                  customClass: 'my-popover-class',
                  placement: 'auto',
                  content: 'Performance'
                }"
                style="width: 44px;height: 35px;"
                @click="setViewType('activity')"
                class="quadButton"
                :variant="isActiveRoute('objectives')"
                squared
              >
                <font-awesome-icon icon="fa-chart-line" />
              </b-button>
            </router-link>
          </b-col>
          <b-col cols="12" class="text-center mb-2">
            <router-link exact to="/handbook" class="side-nav-link-ref">
              <b-button
                v-b-popover.hover="{
                  customClass: 'my-popover-class',
                  placement: 'bottom',
                  content: 'Handbook'
                }"
                style="width: 44px;height: 35px;"
                @click="setViewType('handbook')"
                class="quadButton"
                :variant="isActiveRoute('handbook')"
                squared
              >
                <font-awesome-icon icon="fa-handshake" />
              </b-button>
            </router-link>
          </b-col>
          <b-col v-if="isDevEnv()" cols="12" class="text-center mb-2">
            <router-link
              exact
              to="/meetings"
              class="border border-red side-nav-link-ref"
            >
              <b-button
                v-b-popover.hover="{
                  customClass: 'my-popover-class',
                  placement: 'bottom',
                  content: 'Meetings'
                }"
                style="width: 44px;height: 35px;"
                @click="setViewType('meetings')"
                class="quadButton"
                :variant="isActiveRoute('meetings')"
                squared
              >
                <font-awesome-icon icon="fa-file-alt" />
              </b-button>
            </router-link>
          </b-col>
          <!-- <b-col cols="12" class="text-center mb-2">
                <router-link exact to="/scheduler" class="border border-red side-nav-link-ref">
                    <b-button v-b-popover.hover="{  customClass: 'my-popover-class', placement: 'bottom',  content: 'Scheduler' }"  style="width: 44px;height: 35px;" @click="setViewType('meetings');" class="quadButton" :variant="isActiveRoute('scheduler')" squared>
                        <font-awesome-icon icon="fa-clock"/>
                    </b-button>
                </router-link>
            </b-col> -->
        </b-row>

        <div v-if="is_collapsed == false">
          <LevelMenu v-if="show_menu" />
          <!-- old nav menu 
            <NavigationMenu v-if="true != true" />-->

          <SearchModal />
        </div>
      </div>
      <!-- Sidebar -->
    </b-overlay>
  </div>
</template>
<style>
.my-popover-class {
  background-color: #74788d !important;
}
.my-popover-class .popover-body {
  padding: 3px;
  color: white !important;
}
</style>
