<template>
    <div class="round-progress-bar">
        <svg :width="overall_size" :height="overall_size" :viewBox="`0 0 ${overall_size} ${overall_size}`">
            <circle class="progress-bar__background" :cx="circle_size" :cy="circle_size" :r="radius" fill="transparent" stroke="#c7c7c7"
                stroke-width="1.5"></circle>
            <circle class="progress-bar__foreground" :cx="circle_size" :cy="circle_size" :r="radius" fill="transparent" :stroke="getStrokeColor"
                :stroke-width="getStrokeWidth" stroke-linecap="round" :stroke-dasharray="circumference"
                :stroke-dashoffset="circumference * (1 - percentage / 100)" :transform="`rotate(-95 ${circle_size} ${circle_size})`"></circle>
            <text :style="getTextAltColor" :class="[getTextSizeClass]" x="50%" y="50%" text-anchor="middle" dy=".3em">{{ percentage }}%</text>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'RoundProgressBar',
    props: {
        percentage: {
            type: Number,
            required: true,
            validator: (value) => value >= 0 && value <= 100,
        },
        size:{
            type: String,
            required: false,
            default: 'sm',
        },
        color:{
            required: false,
            default: false,
        },
        textColor:{
            required: false,
            default: ''
        },
        strokeWidth:{
            default: false,
        }
    },
    computed: {
        getTextAltColor(){
            return this.textColor ? `fill: ${this.textColor};` : '';
        },
        getStrokeWidth(){
            return this.strokeWidth != false ? this.strokeWidth : 2;
        },
        getTextSizeClass(){
            switch(this.size){
                case 'md':{
                    //* sm
                    return 'text-size-md';
                }
                default:{
                    //* sm
                    return 'text-size-sm';
                }
            }
        },
        radius(){
            switch(this.size){
                case 'md':{
                    //* sm
                    return 15;
                }
                default:{
                    //* sm
                    return 11;
                }
            }
        },
        circle_size(){
            switch(this.size){
                case 'md':{
                    //* sm
                    return 18;
                }
                default:{
                    //* sm
                    return 12;
                }
            }
        },
        overall_size(){
            switch(this.size){
                case 'md':{
                    //* sm
                    return 36;
                }
                default:{
                    //* sm
                    return 24;
                }

            }
        },
        circumference() {
            return 2 * Math.PI * this.radius; // Circumference of the circle (C = 2πr, r = 10)
        },
        getStrokeColor(){
            if(this.percentage == 100){
                return '#35CA23';   
            }
            else{
                if(this.color){
                    return this.color;
                }
                else{return '#1f44ff';}
                
            }
        }
    },
};
</script>

<style scoped>

text {
    fill: #333;
    
    /* Adjust if necessary to fit inside the circle */
}
.text-size-sm{
    font-size: 0.49rem;
}
.text-size-md{
    font-size: 0.6rem;
}
</style>
