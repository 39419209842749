<template>
    <div>
        <b-form class="jobForm">
                <b-row>
                    <b-col cols="2">Job Title</b-col>
                    <b-col cols="4">
                        <b-form-input v-model="job_data.job_title"></b-form-input>
                    </b-col>
                    <b-col cols="2">Company</b-col>
                    <b-col cols="4">
                        <b-form-input v-model="job_data.job_company"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="2">Country</b-col>
                    <b-col cols="4">
                        <b-form-input v-model="job_data.job_country"></b-form-input>
                    </b-col>
                    <b-col cols="2">Job Description</b-col>
                    <b-col cols="4">
                        <b-form-input v-model="job_data.job_description"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="2">Region</b-col>
                    <b-col cols="4">
                        <b-form-input v-model="job_data.job_region"></b-form-input>
                    </b-col>
                    <b-col cols="2">Start Date</b-col>
                    <b-col cols="4">
                        <b-form-input type="date" v-model="job_data.start_date"></b-form-input>
                    </b-col>
                </b-row>
                <b-row >
                    <b-col cols="2">City</b-col>
                    <b-col cols="4">
                        <b-form-input v-model="job_data.job_city"></b-form-input>
                    </b-col>
                </b-row>
                
                <b-row style="margin-bottom: 0px !important;">
                    <b-col>
                        <div style="float:right;">
                            <b-button v-if="is_new_job" @click="$bvModal.hide('jobDetails')">Cancel</b-button>
                            <b-button v-else @click="$emit('back')">Cancel</b-button>&nbsp;
                            <b-button variant="success" @click="saveJob">Save</b-button>
                        </div>
                    </b-col>
                </b-row>
        </b-form>
        <b-overlay no-wrap :show="is_saving"></b-overlay>
    </div>
</template>

<script>
import { format } from "date-fns";
import _ from 'lodash'
import { peopleMethods, peopleComputed, levelComputed, adminComputed, levelMethods, } from '@/state/helpers'
export default {
    data:() => ({
        job_data:{
            job_title: '',
        },
        is_saving: false,
    }),
    props:{
        is_new_job:{
            default: false
        },
        query:{
            default: ''
        },
        job_object:{
            default: function(){
                return {}
            },
        }
    },
    computed:{
        ...levelComputed,
        ...adminComputed,
        ...peopleComputed,
    },
    methods:{
        ...levelMethods,
        ...peopleMethods,
        saveJob(){
            if(this.is_new_job){
                this.saveNew();
            }
            else{
                this.saveExisting();
            }
        },
        saveExisting(){
            let date = null;
            if(this.job_data.start_date === '' || this.job_data.start_date == undefined){
                date = format(new Date(),'yyyy-MM-dd');
            }
            else{
                date = format(new Date(this.job_data.start_date), 'yyyy-MM-dd');
            }
            let params = _.cloneDeep(this.job_data);
            params.start_date = date;

            let params_object = {jobs:[params]};
            this.is_saving = true;
            this.saveJobDetails(params_object)
            .then(()=>{
                this.loadMenuUsers(this.current_level_id)
                .then(()=>{
                    this.loadHiringData(this.current_level_id)
                    .then(()=>{
                        this.$emit('changedJob');
                        this.$emit('back');
                    })
                })
            })
        },
        saveNew(){
            let date = null;
            if(this.job_data.start_date === '' || this.job_data.start_date == undefined){
                date = format(new Date(),'yyyy-MM-dd');
            }
            else{
                date = format(new Date(this.job_data.start_date), 'yyyy-MM-dd');
            }
            let params = {
                id:-1,
                level_id: this.current_level_id,
                job_title: this.job_data.job_title,
                job_description: (this.job_data.job_description != undefined && this.job_data.job_description != '') ? this.job_data.job_description : 'N/A',
                job_company:  (this.job_data.job_company != undefined && this.job_data.job_company != '') ? this.job_data.job_company : 'N/A',
                job_country:  (this.job_data.job_country != undefined && this.job_data.job_country != '') ? this.job_data.job_country : 'N/A',
                job_region:  (this.job_data.job_region != undefined && this.job_data.job_region != '') ? this.job_data.job_region : 'N/A',
                job_province: 'N/A',
                job_city:  (this.job_data.job_city != undefined && this.job_data.job_city != '') ? this.job_data.job_city : 'N/A',
                status: 0,
                start_date: date,
                basic_from: 0,
                basic_to: 0,
                bonus: 0,
                report_to_user_id: this.core.id,
            }
            let params_object = {jobs:[params]};
            this.is_saving = true;
            this.saveJobDetails(params_object)
            .then(()=>{
                this.loadMenuUsers(this.current_level_id)
                .then(()=>{

                
                this.loadHiringData(this.current_level_id)
                .then(()=>{
                    this.$nextTick(()=>{
                        let index = _.findIndex(this.levelHiringData, {job_title: this.job_data.job_title})
                        if(index != -1){
                            let update_job_params = {
                                user_id: this.core.id,
                                job_id: this.levelHiringData[index].id,
                                start_date: date,
                            }
                            this.updateEmployeeJob(update_job_params)
                            .then(()=>{
                                this.$emit('changedJob');
                                this.$nextTick(()=>{
                                    this.$bvModal.hide('jobDetails');
                                    this.is_saving = false;
                                });
                            })
                            .catch(()=>{
                                this.is_saving = false;
                            })
                        }
                        else{
                            alert('Error finding new job id...');
                            this.is_saving = false;
                        }
                    })
                    
                })
                .catch(()=>{
                    this.is_saving = false;
                })
                })
            })
            .catch(()=>{
                this.is_saving = false;
            })
        }
    },
    mounted(){
        console.log(this.job_object)
        if(this.is_new_job && this.job_data.start_date){
            console.log('1')
            this.job_data['job_title'] = this.query;
            this.job_data.start_date = format(new Date(), 'yyyy-MM-dd')
        }
        else{
            
            console.log('2')
            this.job_data = _.cloneDeep(this.job_object);
            console.log(JSON.parse(JSON.stringify(this.job_data)))
            if(this.job_data.start_date) {
                this.job_data.start_date = format(new Date(this.job_data.start_date), 'yyyy-MM-dd')
            } else {
                this.job_data.start_date = format(new Date(), 'yyyy-MM-dd')
            }
            
        }
    },
}
</script>

<style>
.jobForm>.row{
    margin-bottom: 1rem;
}
.jobForm>.row>.col-2{
    padding-top: 0.5rem;
}
</style>