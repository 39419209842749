<template>
    <div>
        <BaseCard>
            <template #header>
                <span class="text-secondary">
                    {{ item.data.room_type }}
                </span>
                &nbsp;
                Chat Message
            </template>
            <template #title>
                {{ itemName }}
            </template>
            
            <template #body>
                <pre>{{ item }}</pre>
            </template>
        </BaseCard>
        <b-overlay no-wrap :show="is_loading"></b-overlay>
    </div>
</template>

<script>
import { adminMethods } from '@/state/helpers';
import BaseCard from './base-card.vue';
export default {
    components:{
        BaseCard,
    },
    props:{
        item:{
            type: Object,
            required: true,
        },
        is_unread_notification:{
            type: Boolean,
            required: false,
        }
    },
    data:() => ({
        is_loading: false,
    }),
    computed:{
        itemName(){
            return this.item.data.key_action_name;
        },
        fromUserName(){
            return '';
        }
    },
    methods:{
        ...adminMethods,
        markAsRead(){
            this.is_loading = true;
            this.markNotificationAsRead({id: this.item.id})
            .then(()=>{
                this.loadNotifications()
                .then(()=>{
                    this.is_loading = false;
                })
            })
            .catch(()=>{
                this.is_loading = false;
            })
        }
    },
    mounted(){},
}
</script>

<style>

</style>