import { render, staticRenderFns } from "./level-menu.vue?vue&type=template&id=2e1c1650&scoped=true"
import script from "./level-menu.vue?vue&type=script&lang=js"
export * from "./level-menu.vue?vue&type=script&lang=js"
import style0 from "./level-menu.vue?vue&type=style&index=0&id=2e1c1650&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e1c1650",
  null
  
)

export default component.exports